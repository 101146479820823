import { useState, useContext } from "react";
import { TextField, Box, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import BaseModal from "@/components/Modal/BaseModal";
import { save_sentry_workaround } from "@/services/Blar/Integrations";
import { IntegrationCardProps } from "../../pages/Settings/components/Integrations/types";
import { Context } from "../../ContextProvider";
import CopyableTextField from "../TextField/CopyableTextField";

type SentryModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  integration: IntegrationCardProps;
  setCreatedObject: (createdObject: number) => void;
};

const SentryModal = ({
  open,
  setOpen,
  integration,
  setCreatedObject,
}: SentryModalProps) => {
  const [clientSecret, setClientSecret] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [loading, setLoading] = useState(false);
  const { showMessage, companyId } = useContext(Context);

  const webhookUrl =
    process.env.REACT_APP_API_URL +
    `/integrations/sentry/internal/webhook/${companyId}/`;

  const handleClose = () => {
    setClientSecret("");
    setApiKey("");
    setOpen(false);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      await save_sentry_workaround({
        api_key: apiKey,
        client_secret: clientSecret,
        source: integration.dbName,
      });
      setCreatedObject(Date.now());
      showMessage("success", "Sentry saved successfully");
      setLoading(false);
      handleClose();
    } catch (error) {
      showMessage("error", "Error saving Sentry");
      setLoading(false);
    }
  };

  const handleMoreInfo = () => {
    // Replace this with the actual link to your tutorial or documentation
    window.open("https://blar.io/docs", "_blank", "noopener noreferrer");
  };

  return (
    <BaseModal
      open={open}
      onClose={handleClose}
      title={`Add ${integration.name} Integration`}
      style={{ width: "55%" }}
    >
      <Typography variant="subtitle1" sx={{ marginRight: "5px" }}>
        Your webhook URL is:
      </Typography>
      <CopyableTextField
        sx={{ marginBottom: "20px" }}
        text={webhookUrl}
        showMessage={showMessage}
        messageText="Webhook URL copied"
      />

      <Typography variant="subtitle1" sx={{ marginRight: "5px" }}>
        Client Secret:
      </Typography>
      <TextField
        hiddenLabel
        value={clientSecret}
        onChange={(e) => setClientSecret(e.target.value)}
        type="password"
        fullWidth
        margin="normal"
        variant="filled"
      />
      <Typography variant="subtitle1" sx={{ marginRight: "5px" }}>
        Token:
      </Typography>
      <TextField
        hiddenLabel
        value={apiKey}
        onChange={(e) => setApiKey(e.target.value)}
        fullWidth
        type="password"
        margin="normal"
        variant="filled"
      />

      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <Typography variant="body2">
          Need more info? Check out our{" "}
          <span
            style={{ color: "#4593F8", cursor: "pointer" }}
            onClick={handleMoreInfo}
          >
            tutorial
          </span>{" "}
          for setting up Sentry.
        </Typography>
        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={handleSave}
          disabled={!apiKey || !clientSecret}
          color="secondary"
        >
          Save
        </LoadingButton>
      </Box>
    </BaseModal>
  );
};

export default SentryModal;
