import React, { useState } from "react";
import { Box, Divider, Popover, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";

interface anchorOrigin {
  vertical: "top" | "center" | "bottom";
  horizontal: "left" | "center" | "right";
}

interface transformOrigin {
  vertical: "top" | "center" | "bottom";
  horizontal: "left" | "center" | "right";
}

type AnchorMenuProps = {
  title: string;
  children: React.ReactNode;
  anchorEl: HTMLElement | null;
  setAnchorEl: (value: HTMLElement | null) => void;
  anchorOrigin: anchorOrigin;
  transformOrigin?: transformOrigin;
  sx?: any;
};

const AnchorMenu: React.FC<AnchorMenuProps> = ({
  title,
  children,
  anchorEl,
  setAnchorEl,
  anchorOrigin,
  transformOrigin = { vertical: "top", horizontal: "left" },
  sx,
}) => {
  const theme = useTheme();

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Popover
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClose={handleClose}
      slotProps={{
        paper: {
          sx: {
            border: "1px solid",
            backgroundColor: theme.palette.background.paper,
            borderColor: theme.palette.divider,
            padding: 0,
            minWidth: 230,
            ...sx,
          },
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 1,
          paddingLeft: 2,
        }}
      >
        <Typography variant="body2" sx={{ textAlign: "left" }}>
          {title}
        </Typography>
        <CloseIcon
          sx={{ color: "", cursor: "pointer", height: 20, width: 20 }}
          onClick={handleClose}
        />
      </Box>
      <Divider orientation="horizontal" sx={{ width: "100%" }} />
      <Box sx={{ padding: 2 }}>{children}</Box>
    </Popover>
  );
};

export default AnchorMenu;
