import { TableRowData } from "@/components/Tables/types";
import { Integration, IntegrationPaginatedResponse } from "./types";

export function convertIntegrationsToRow(
  data: IntegrationPaginatedResponse
): TableRowData[] {
  return data.results.map((integration: Integration) => ({
    _id: { content: integration.id, type: "hidden" },
    Name: { content: integration.source, type: "string" },
    "Created By": { content: integration.user_email, type: "string" },
    "API Key": {
      content:
        integration.integration_type === "api_key"
          ? integration.api_key_str
          : "OAuth",
      type: "string",
    },
    "Created At": {
      content: new Date(integration.created_at),
      type: "date",
    },
    Active: { content: integration.active, type: "boolean" },
  }));
}
