import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { TooltipProps } from "@mui/material/Tooltip";

interface CustomTooltipProps extends TooltipProps {
  title: string;
  children: React.ReactElement;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  title,
  children,
  ...props
}) => {
  return (
    <Tooltip
      title={title}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -12],
              },
            },
          ],
        },
      }}
      {...props}
    >
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
