import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";
import { useReactFlow } from "reactflow";
import {
  AffectedNodeInfo,
  GetNodeOutput,
} from "../../../../interfaces/IBackendOutputs";
import CodeDisplay from "../../../../components/CodeDisplay/CodeDisplay";
interface InfoPanelProps {
  nodeInfoToShow?: GetNodeOutput;
  loading: boolean;
  setHightLightNode: (nodeId: string | undefined) => void;
  height?: number | string;
}

const InfoPanel: React.FC<InfoPanelProps> = ({
  nodeInfoToShow,
  loading,
  setHightLightNode,
  height,
}) => {
  const reactFlow = useReactFlow();

  const changeViewport = (nodeId: string, level: number) => {
    if (!reactFlow) return;
    const node = reactFlow.getNode(nodeId);
    if (!node) return;

    setHightLightNode(nodeId);
    reactFlow.fitView({
      nodes: [node],
      duration: 5000,
      padding: 0.45,
      includeHiddenNodes: true,
    });
  };

  function getLastThreeAndJoin(input: string): string {
    const parts = input.split(".");
    const lastThreeParts = parts.slice(-3);
    return lastThreeParts.join("/");
  }

  return (
    <Paper
      sx={{
        height: height ? height : "25vh",
        borderRadius: "20px",
        padding: "15px",
        overflow: "scroll",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        msOverflowStyle: "none", // IE and Edge
        scrollbarWidth: "none", // Firefox
      }}
    >
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <Typography
            variant="h6"
            mb={1}
            sx={{
              textAlign: "left",
              padding: "8px",
              wordWrap: "break-word",
              overflowWrap: "break-word",
            }}
          >
            {getLastThreeAndJoin(nodeInfoToShow!.nodeDetail.path)}
          </Typography>
          <CodeDisplay
            code={nodeInfoToShow!.nodeDetail.text}
            width={"100%"}
            height={170}
          />
          <Typography
            variant="subtitle1"
            sx={{ textAlign: "left", padding: "8px" }}
          >
            Affects:
          </Typography>
          <Box
            display={"inline-flex"}
            sx={{
              justifyContent: "flex-start",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            {nodeInfoToShow!.affects.map(
              (nodeInfo: AffectedNodeInfo, index: number) => (
                // <Paper
                //   key={index}
                //   sx={{ backgroundColor: "black", margin: "5px" }}
                // >
                //   <Typography
                //     variant="body1"
                //     textAlign={"left"}
                //     color={"white"}
                //   >
                //     #{nodeInfo.name}
                //   </Typography>
                // </Paper>
                <Button
                  key={index}
                  variant="contained"
                  onClick={() => changeViewport(nodeInfo.id, nodeInfo.level)}
                  color="primary"
                >
                  #{nodeInfo.name}
                </Button>
              )
            )}
          </Box>
        </Box>
      )}
    </Paper>
  );
};

export default InfoPanel;
