import React from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  SxProps,
  Theme,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

interface CopyableTextFieldProps {
  text: string;
  showMessage: (
    type: "success" | "error" | "info" | "warning",
    message: string
  ) => void;
  messageText: string;
  sx?: SxProps<Theme>;
}

const CopyableTextField: React.FC<CopyableTextFieldProps> = ({
  text,
  showMessage,
  messageText,
  sx,
}) => {
  const handleCopyWebhookUrl = () => {
    navigator.clipboard.writeText(text);
    showMessage("success", messageText);
  };

  return (
    <TextField
      hiddenLabel
      value={text}
      fullWidth
      sx={{
        ...sx,
        "& .MuiInputBase-input": {
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      }}
      InputProps={{
        readOnly: true,

        endAdornment: (
          <InputAdornment position="end">
            <IconButton size="small" onClick={handleCopyWebhookUrl}>
              <ContentCopyIcon fontSize="small" />
              <Typography variant="body2" sx={{ marginLeft: "4px" }}>
                Copy
              </Typography>
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default CopyableTextField;
