import {
  CreateRepoParams,
  SyncRepoParams,
} from "@/pages/Settings/components/Repos/types";
import {
  GetNodeOutput,
  QueryNodesListOutput,
} from "../../interfaces/IBackendOutputs";
import { blarClient } from "./Client";

export const list_node = async (): Promise<QueryNodesListOutput> => {
  const query = await blarClient.request(
    "get",
    `/repo_graph/nodes/node_level?group=true`
  );
  return query?.data;
};

export const get_node = async (nodeId: string): Promise<GetNodeOutput> => {
  const query = await blarClient.request("get", `/repo_graph/nodes/${nodeId}`);
  return query?.data;
};

export const get_max_level = async () => {
  return blarClient.request("get", `/repo_graph/repos/max_level`);
};

export const list_repos = async () => {
  return blarClient.request("get", `/repo_graph/repos/list`);
};

export const create_repo = async (params: CreateRepoParams) => {
  return blarClient.request("post", "/repo_graph/repos/create/", params);
};

export const sync_repo = async (params: SyncRepoParams) => {
  return blarClient.request("post", "/repo_graph/repos/sync/", params);
};

export const delete_repo = async (repo_id: string) => {
  return blarClient.request("post", "/repo_graph/repos/delete/", { repo_id });
};

export const checkRepoState = async (repo_id: string) => {
  return blarClient.request("post", "/repo_graph/repos/state/", { repo_id });
};

export const search_node = async (
  path: string | null = "",
  repo_id: string | null = null
) => {
  return blarClient.request("get", `/repo_graph/nodes/search`, null, {
    path,
    repo_id,
  });
};

export const get_root_hierarchy = async (
  path: string | null = "",
  repo_id: string | null = null
) => {
  return blarClient.request("get", `/repo_graph/nodes/hierarchy`);
};

export const get_node_hierarchy = async (node_id: string | null) => {
  return blarClient.request("get", `/repo_graph/nodes/hierarchy_node`, null, {
    node_id,
  });
};

export const checkSyncingRepos = async () => {
  return blarClient.request("get", "/repo_graph/repos/syncing/");
};
