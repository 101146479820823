import React from "react";
import Avatar from "@mui/material/Avatar";
import { makeStyles } from "@mui/styles";
import { IUserAvatar } from "./types";

interface StyleProps {
  color: string;
  secondaryColor: string;
}

const useStyles = makeStyles({
  avatar: {
    color: "#ffffff",
    backgroundImage: (props: StyleProps) =>
      `linear-gradient(-45deg, #${props.color}, #${props.secondaryColor})`,
  },
});

interface UserAvatarProps {
  user: IUserAvatar;
}

const UserAvatar: React.FC<UserAvatarProps> = ({ user }) => {
  const { firstName, lastName, email, color, secondaryColor } = user;
  const classes = useStyles({ color, secondaryColor });

  // Determine initials
  let initials = "";
  if (firstName && lastName) {
    initials = `${firstName[0] || ""}${lastName[0] || ""}`.toUpperCase();
  } else if (email) {
    // Split email by "@" and then by "."
    const emailName = email.split("@")[0];
    const parts = emailName.split(".");
    initials = parts
      .map((part) => part[0])
      .join("")
      .toUpperCase();
  }

  return (
    <Avatar
      sx={{ width: 20, height: 20, fontSize: 10 }}
      className={classes.avatar}
    >
      {initials || "U"}
    </Avatar>
  );
};

export default UserAvatar;
