import React, { useEffect } from "react";

interface StripePricingTableProps {
  companyId: string;
}
const StripePricingTable: React.FC<StripePricingTableProps> = ({
  companyId,
}) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return React.createElement("stripe-pricing-table", {
    "pricing-table-id": process.env.REACT_APP_STRIPE_PRICING_TABLE_ID,
    "publishable-key": process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
    "client-reference-id": companyId,
  });
};

export default StripePricingTable;
