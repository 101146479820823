import { useState, ChangeEvent, useContext } from "react";
import {
  TextField,
  Box,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import BaseModal from "@/components/Modal/BaseModal";
import { Context } from "../../../../../ContextProvider";
import { create_api_key } from "@/services/Blar/ApiKeys";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
type AddApiKeyModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  setCreatedObject: (createdObject: number) => void;
};

const AddApiKeyModal = ({
  open,
  setOpen,
  setCreatedObject,
}: AddApiKeyModalProps) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [apiKey, setApiKey] = useState<string | null>(null);
  const { showMessage } = useContext(Context);

  const handleClose = () => {
    setName(""); // clear the input field when the modal is closed
    setApiKey(null);
    setOpen(false);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const response = await create_api_key({ name: name });
      setApiKey(response.data["api_key"]);
      setCreatedObject(Date.now());
      showMessage("success", "API Key created successfully");
      setLoading(false);
    } catch (error) {
      showMessage("error", "Error saving API Key");
      setLoading(false);
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleCopy = () => {
    if (apiKey) {
      navigator.clipboard.writeText(apiKey);
      showMessage("success", "API Key copied to clipboard");
    }
  };

  return (
    <BaseModal
      open={open}
      onClose={handleClose}
      title={`Add API Key`}
      style={{ width: "50%" }}
    >
      {apiKey ? (
        <>
          <Typography variant="subtitle1" sx={{ marginRight: "5px" }}>
            API Key:
          </Typography>
          <TextField
            hiddenLabel
            value={apiKey}
            margin="normal"
            variant="filled"
            fullWidth
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleCopy}>
                    <ContentCopyIcon sx={{ color: "black" }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Typography variant="body2" color="error" sx={{ marginTop: "10px" }}>
            Please copy this API key now. You will not be able to see it again.
          </Typography>
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              onClick={handleCopy}
              sx={{ backgroundColor: "#1a7f64", marginRight: "10px" }}
            >
              Copy
            </Button>
            <Button variant="contained" onClick={handleClose}>
              Close
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Typography variant="subtitle1" sx={{ marginRight: "5px" }}>
            Name:
          </Typography>
          <TextField
            hiddenLabel
            value={name}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            variant="filled"
          />
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <LoadingButton
              loading={loading}
              variant="contained"
              onClick={handleSave}
              disabled={!name}
              sx={{ backgroundColor: "#1a7f64" }}
            >
              Save
            </LoadingButton>
          </Box>
        </>
      )}
    </BaseModal>
  );
};

export default AddApiKeyModal;
