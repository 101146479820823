import React from 'react';
import Typography from '@mui/material/Typography';

interface TimestampProps {
  timestamp: string;
}

const Timestamp: React.FC<TimestampProps> = ({ timestamp }) => {

  const formatTimestamp = (time: string) => {
    const date = new Date(time);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
  }

  return (
    <Typography variant="caption" color="text.secondary">
      {formatTimestamp(timestamp).toUpperCase()}
    </Typography>
  );
};

export default Timestamp;