import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent:"center",
    alignItems: "start",
    marginTop: "10%",
    height: "100%",
    textAlign: 'center',
  },
  logosContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "500px",
    marginBottom: "20px",
  },
  logo: {
    width: "100%",
    height: "auto",
  },
  gridItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loadingBarContainer: {
    height: "20px"
  }
})

export default useStyles;
