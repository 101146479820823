import React, { useContext, useEffect, useRef, useState } from "react";

import { Box } from "@mui/material";
import useStyles from "./styles/Chat";
import ChatBody from "./components/Chat/ChatBody";
import { useTheme } from "@mui/material/styles";
import {
  Agent,
  ChatRoomResponse,
  InputTag,
  TutorialState,
} from "./components/types";
import { useLocation, useNavigate } from "react-router-dom";
import { ListChatRoomFilters, TriggerType } from "@/interfaces/IChatRoom";
import Inbox from "./components/Inbox/Inbox";
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS } from "react-joyride";
import { useSetState } from "react-use";

import { tutorialSteps } from "./components/tutorialSteps";
import {
  AgentStep,
  AgentStepType,
  FileNode,
  Message,
} from "./components/types";
import {
  get_chat_room_messages,
  mark_as_read,
  process_message_chunk,
  send_message,
  SendMessageParams,
  SendTagParams,
} from "@/services/Blar/Chat";
import { Context } from "@/ContextProvider";
import { w3cwebsocket } from "websocket";
import { connectWebSocket } from "@/services/Websocket/connect";

const defaultFilters: ListChatRoomFilters = {
  code_error_priority: [],
  code_error_state: [],
};

const defaultInboxFilters: ListChatRoomFilters = {
  code_error_priority: [],
  code_error_state: ["open", "acknowledged"],
  code_error_assigned_to: "me",
};

const defaultSentryFilters: ListChatRoomFilters = {
  code_error_priority: [],
  code_error_state: ["open", "acknowledged"],
  code_error_assigned_to: "all",
  trigger_type: "codeerror",
};

const Chat: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const socketRef = useRef<w3cwebsocket | null>(null);

  const { syncing } = useContext(Context);

  const sidebarRef = useRef<HTMLDivElement>(null);
  const closeChatRef = useRef(false);
  const closeNotificationsRef = useRef(false);
  const { showMessage, access, refresh } = useContext(Context);
  const [isResizing, setIsResizing] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(300);
  const [selectedChatRoom, setSelectedChatRoom] = useState<number | null>(null);
  const selectedChatRoomRef = useRef(selectedChatRoom);
  const [refreshChatRoomsTimestamp, setRefreshChatRoomsTimestamp] =
    useState<number>(0);
  const [selectedAgent, setSelectedAgent] = useState<Agent | undefined>(
    undefined
  );
  const [selectedNode, setSelectedNode] = useState<FileNode | undefined>(
    undefined
  );
  const selectedNodeRef = useRef(selectedNode);

  const [messages, setMessages] = useState<Message[]>([]);
  const [chatRoomData, setChatRoomData] = useState<ChatRoomResponse>();
  const [loadingResponse, setLoadingResponse] = useState(false);
  const [agentStep, setAgentStep] = useState<AgentStep | null>(null);
  const [isCreatingNewChatRoom, setIsCreatingNewChatRoom] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Filters
  const triggerType = queryParams.get("triggerType") as TriggerType;
  let initialFilters = defaultFilters;
  if (triggerType) {
    if (triggerType === "baseuser") {
      initialFilters = { ...defaultFilters, trigger_type: "baseuser" };
    }
    if (["sentry"].includes(triggerType)) {
      initialFilters = {
        ...defaultSentryFilters,
        code_error_source: triggerType,
      };
    }
  } else {
    initialFilters = defaultInboxFilters;
  }
  const [filters, setFilters] = useState<ListChatRoomFilters>(initialFilters);

  // Tutorial
  const tutorial = Boolean(queryParams.get("tutorial"));
  const [tutorialState, setState] = useSetState<TutorialState>({
    run: tutorial && !syncing,
    newChatOpen: false,
    stepIndex: 0,
    steps: tutorialSteps,
  });
  const { run, newChatOpen, stepIndex, steps } = tutorialState;

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, status, type } = data;

    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
      setState({ run: false, stepIndex: 0 });
    } else if (
      ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)
    ) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

      if (newChatOpen && index === 0) {
        setTimeout(() => {
          setState({ run: true });
        }, 400);
      } else if (newChatOpen && index === 1 && action === ACTIONS.PREV) {
        setState({
          run: false,
          newChatOpen: false,
          stepIndex: nextStepIndex,
        });

        setTimeout(() => {
          setState({ run: true });
        }, 400);
      } else {
        // Update state to advance the tour
        setState({
          stepIndex: nextStepIndex,
        });
      }
    }
  };

  useEffect(() => {
    if (tutorial && !syncing) {
      setState({ run: true });
    }
    if (syncing) {
      setState({ run: false });
    }
  }, [syncing, tutorial, setState]);

  // TODO: Separate to a different component
  const startResizing = React.useCallback(() => {
    setIsResizing(true);
  }, []);

  const stopResizing = React.useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = React.useCallback(
    (mouseMoveEvent: MouseEvent) => {
      if (isResizing && sidebarRef.current) {
        setSidebarWidth(
          mouseMoveEvent.clientX -
            sidebarRef.current.getBoundingClientRect().left
        );
      }
    },
    [isResizing]
  );

  const handleChooseStartingPoint = React.useCallback((node: FileNode) => {
    setSelectedNode(node);
  }, []);

  const handleRoomChange = React.useCallback(
    (chatRoomId: number) => {
      if (socketRef.current) {
        closeChatRef.current = true;
        socketRef.current.close(1000, "Switching chat rooms");
      }

      const chatUrl = `${process.env.REACT_APP_WS_URL}/chat/${chatRoomId}/?token=${access}&refresh_token=${refresh}`;
      const onMessage = (event: any) => {
        try {
          const json_data = process_message_chunk(event.data);

          if (json_data.type === "info") {
            if (json_data.message?.message === "Agent Started") {
              setLoadingResponse(true);
            } else if (json_data.message?.message === "Agent Finished") {
              setLoadingResponse(false);
            }
          }

          if (json_data.type === AgentStepType.Function) {
            if (json_data.response && json_data.call === "get_code_by_id") {
              const hasCorrectFormat =
                json_data.response.node_name && json_data.response.file_path;
              if (hasCorrectFormat) {
                setAgentStep(json_data);
              }
            }
          }
          if (
            json_data.type === AgentStepType.AIM ||
            json_data.type === AgentStepType.User
          ) {
            if (json_data.message) {
              setMessages((prevMessages) => {
                return [...prevMessages, json_data.message!];
              });
              setAgentStep(null);
            }
          }
        } catch (error) {}
      };
      const onOpen = (socket: w3cwebsocket | null) => {
        socketRef.current = socket;
      };
      const onClose = (error: any) => {
        setAgentStep(null);
      };
      connectWebSocket(
        chatUrl,
        onMessage,
        onOpen,
        undefined,
        onClose,
        closeChatRef
      );

      setSelectedChatRoom(chatRoomId);
      setAgentStep(null);
    },
    [access, refresh]
  );

  const handleOpenNewChat = React.useCallback(
    async (agent: Agent, node: FileNode) => {
      setSelectedChatRoom(null);
      setSelectedAgent(agent);
      setSelectedNode(node);
      setMessages([]);
      try {
        setIsCreatingNewChatRoom(true);

        const response = await send_message({
          node_id: node.node_id,
          agent: agent,
        });
        setRefreshChatRoomsTimestamp(Date.now());
        setSelectedChatRoom(response.data.id);
        handleRoomChange(response.data.id);
        setIsCreatingNewChatRoom(false);
      } catch (error) {}
      navigate(`/chat?triggerType=${encodeURIComponent("baseuser")}`);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  );

  useEffect(() => {
    selectedNodeRef.current = selectedNode;
  }, [selectedNode]);

  const handleSend = React.useCallback(
    async (input: string) => {
      setLoadingResponse(true);
      if (input === "") {
        return;
      }
      setState({ run: false, stepIndex: 0 });

      if (!selectedChatRoom) {
        showMessage("error", "Select a chat room to send a message");
        return;
      }
      // opening a connection to the server to begin receiving events from it
      const newMessage = input;

      setAgentStep(null);
      let params: SendMessageParams;

      params = {
        message: newMessage,
        node_id: selectedNodeRef.current?.node_id,
        type: "blar_agent_message",
      };
      setSelectedNode(undefined);

      try {
        if (socketRef.current?.readyState === WebSocket.OPEN) {
          socketRef.current.send(JSON.stringify(params));
        } else {
          // Handle the case where the WebSocket is not open
          setAgentStep(null);
          setLoadingResponse(false);
          showMessage("error", "WebSocket is not open. Cannot send message.");
        }
      } catch (error) {
        setAgentStep(null);
        setLoadingResponse(false);
        showMessage("error", "Error sending message");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedChatRoom, selectedNode]
  );

  const handleSendTag = React.useCallback(
    async (input: string, tags: InputTag[]) => {
      setLoadingResponse(true);

      if (!selectedChatRoom) {
        showMessage("error", "Select a chat room to send a message");
        return;
      }
      const newMessage = input;

      setAgentStep(null);
      let params: SendTagParams;
      params = {
        message: newMessage,
        tags: tags,
        type: "tag_message",
      };

      try {
        socketRef.current?.send(JSON.stringify(params));
      } catch (error) {
        setLoadingResponse(false);
        showMessage("error", "Error sending message");
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [selectedChatRoom, selectedNode]
  );

  // Listen for messages from ws
  useEffect(() => {
    const wsNotificationsUrl = `${process.env.REACT_APP_WS_URL}/notifications/?token=${access}&refresh_token=${refresh}`;

    const onMessage = (event: any) => {
      try {
        const message = JSON.parse(event.data as string);
        const notification = message.notification;
        if (notification.data && notification.data.action) {
          handleActions(notification.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    const wsNotifications = connectWebSocket(
      wsNotificationsUrl,
      onMessage,
      undefined,
      undefined,
      undefined,
      closeNotificationsRef
    );

    return () => {
      closeNotificationsRef.current = true;
      closeChatRef.current = true;
      socketRef.current?.close(1000, "Closing chat room");
      wsNotifications.close(1000, "Closing notifications");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleActions = (data: any) => {
    const action = data.action;

    if (action === "refresh_chats") {
      setRefreshChatRoomsTimestamp(Date.now());
    } else if (action === "refresh_chat") {
      if (
        selectedChatRoomRef.current &&
        selectedChatRoomRef.current.toString() === data.chat_room_id
      ) {
        fetchMessages(selectedChatRoomRef.current);
      }
    }
  };

  const fetchMessages = async (chatRoom?: number) => {
    if (chatRoom) {
      try {
        mark_as_read(chatRoom);
      } catch (error) {
        showMessage("error", "Error marking chat as read");
      }
    }

    try {
      const chatRoomResponse = await get_chat_room_messages(
        chatRoom || selectedChatRoom!
      );
      if (chatRoomResponse.task_state === "in_progress") {
        setLoadingResponse(true);
      } else {
        setLoadingResponse(false);
      }

      setMessages(chatRoomResponse.messages);
      setChatRoomData(chatRoomResponse);
    } catch (error) {
      showMessage("error", "Error fetching chat messages");
    }
  };

  useEffect(() => {
    selectedChatRoomRef.current = selectedChatRoom;
    setAgentStep(null);
    if (selectedChatRoom !== null && !isCreatingNewChatRoom) fetchMessages();
    if (selectedChatRoom === null) setChatRoomData(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChatRoom]);

  useEffect(() => {
    window.addEventListener("mousemove", resize);
    window.addEventListener("mouseup", stopResizing);
    return () => {
      window.removeEventListener("mousemove", resize);
      window.removeEventListener("mouseup", stopResizing);
    };
  }, [resize, stopResizing]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const triggerType = queryParams.get("triggerType") as TriggerType;

    if (triggerType) {
      if (triggerType === "baseuser") {
        setFilters({ ...defaultFilters, trigger_type: "baseuser" });
      }
      if (["sentry"].includes(triggerType)) {
        setFilters({
          ...defaultSentryFilters,
          code_error_source: triggerType,
        });
        return;
      }
    } else {
      setFilters(defaultInboxFilters);
    }
  }, [location.search]);

  return (
    <Box className={classes.chatContainer}>
      <Joyride
        callback={handleJoyrideCallback}
        steps={steps}
        continuous={true}
        stepIndex={stepIndex}
        run={run}
        showProgress={true}
        styles={{
          buttonBack: {
            fontFamily: "Ubuntu",
          },
          buttonNext: {
            fontFamily: "Ubuntu",
            fontWeight: "bold",
            display: stepIndex === 3 || stepIndex === 5 ? "none" : "block",
          },
          options: {
            arrowColor: "#161B22",
            backgroundColor: "#161B22",
            primaryColor: "#4593F8",
            textColor: "#FFFFFF",
            zIndex: 1000,
          },
        }}
      />
      <Box
        className={classes.sidebar}
        ref={sidebarRef}
        onMouseDown={(e) => e.preventDefault()}
        sx={{ height: "100%", width: sidebarWidth }}
      >
        <Box className={classes.sidebarContent}>
          <Inbox
            currentChatRoom={selectedChatRoom}
            filters={filters}
            setFilters={setFilters}
            onChatRoomChange={handleRoomChange}
            openNewChat={handleOpenNewChat}
            refreshChatRoomsTimestamp={refreshChatRoomsTimestamp}
            setRefreshChatRoomsTimestamp={setRefreshChatRoomsTimestamp}
            setState={setState}
            tutorialState={tutorialState}
            handleChooseStartingPoint={handleChooseStartingPoint}
          />
        </Box>
        <Box
          className={classes.sidebarResizer}
          onMouseDown={startResizing}
          sx={{
            backgroundColor: theme.palette.background.paper,
            overflow: "hidden",
            border: 0,
          }}
        />
      </Box>

      <Box className={classes.chatBody}>
        <ChatBody
          selectedChatRoom={selectedChatRoom}
          chatRoomData={chatRoomData}
          loading={loadingResponse}
          setLoading={setLoadingResponse}
          messages={messages}
          agentStep={agentStep}
          sendMessage={handleSend}
          selectedAgent={selectedAgent}
          selectedNode={selectedNode}
          setChatRoomData={setChatRoomData}
          setRefreshChatRoomsTimestamp={setRefreshChatRoomsTimestamp}
          handleSendTag={handleSendTag}
        />
      </Box>
    </Box>
  );
};

export default Chat;
