import React, { useContext, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Badge, Button, Grid, Paper, Typography } from "@mui/material";
import MenuSearch from "@/components/SearchBar/MenuSearch";
import { AxiosResponse } from "axios";
import { get_root_hierarchy, search_node } from "@/services/Blar/Repo_graph";
import { Context } from "@/ContextProvider";
import FileStructure from "./FileStructure";
import { FileNode, TutorialState } from "../types";
import CustomTooltip from "@/components/Tooltip/CustomTooltip"; // Import the CustomTooltip component

import { Agent } from "../types";
import CustomToggleButton from "@/components/Buttons/ToggleButton";
import BugReportIcon from "@mui/icons-material/BugReport";
import SpeedIcon from "@mui/icons-material/Speed";
import SecurityIcon from "@mui/icons-material/Security";
import EngineeringIcon from "@mui/icons-material/Engineering";
interface NewChatMenuProps {
  setNewChatAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
  setState: (state: any) => void;
  tutorialState: TutorialState;
  openNewChat: (agent: Agent, node: FileNode) => void;
  selectedChatRoom: number | null;
  handleChooseStartingPoint: (node: FileNode) => void;
}
const NewChatMenu: React.FC<NewChatMenuProps> = ({
  setNewChatAnchorEl,
  setState,
  tutorialState,
  openNewChat,
  selectedChatRoom,
  handleChooseStartingPoint,
}) => {
  const [nodeOptions, setNodeOptions] = useState<FileNode[]>([]);
  const [selectedAgent, setSelectedAgent] = useState<Agent | null>(null);
  const [selectedNode, setSelectedNode] = useState<FileNode | null>(null);
  const [loadingNodes, setLoadingNodes] = useState<boolean>(false);
  const [loadingFilesCount, setLoadingFilesCount] = useState<number>(0);
  const [fileStructure, setFileStructure] = useState<FileNode[]>([]);
  const { showMessage } = useContext(Context);
  const { stepIndex } = tutorialState;

  useEffect(() => {
    // Fetch JSON data on component mount
    const fetchFileStructure = async () => {
      try {
        setLoadingFilesCount((prev) => prev + 1);
        const response = await get_root_hierarchy();
        const data = response.data;
        setFileStructure(data as FileNode[]);
      } catch (error) {
        showMessage("error", "Failed to get files");
      } finally {
        setLoadingFilesCount((prev) => prev - 1);
      }
    };

    fetchFileStructure();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchChange = async (value: string) => {
    try {
      setLoadingNodes(true);
      const response: AxiosResponse = await search_node(value || "");
      const options = response.data as FileNode[];
      setNodeOptions(options);
    } catch (error) {
      showMessage("error", "Failed to search");
    } finally {
      setLoadingNodes(false);
    }
  };

  const handleCreateChatRoom = () => {
    setNewChatAnchorEl(null);
    openNewChat(selectedAgent!, selectedNode!);
    setState({ stepIndex: stepIndex + 1 });
  };

  const handleSelectStartingPoint = () => {
    setNewChatAnchorEl(null);
    handleChooseStartingPoint(selectedNode!);
  };

  const handleAgentSelect = (
    event: React.MouseEvent<HTMLElement>,
    agent: Agent
  ) => {
    setSelectedAgent(agent);
    setState({ stepIndex: stepIndex + 1 });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignContent: "start",
      }}
    >
      <Typography variant="body2" color="text.secondary">
        Choose your agent
      </Typography>
      <Grid
        width={"100%"}
        padding={1}
        spacing={1}
        container
        id="agent-selector"
      >
        <CustomTooltip title="General (Beta)">
          <Grid xs={3} item>
            <CustomToggleButton
              value={Agent.General}
              selected={selectedAgent === Agent.General}
              handleClick={handleAgentSelect}
              sx={{ width: "100%", height: "100%" }}
            >
              <Badge
                badgeContent="Beta"
                variant="dot"
                color="blarWarning"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={{
                  "& .MuiBadge-badge": {
                    right: "-7px",
                  },
                }}
              >
                <EngineeringIcon fontSize="small" />
              </Badge>
            </CustomToggleButton>
          </Grid>
        </CustomTooltip>
        <CustomTooltip title="Debugger">
          <Grid xs={3} item>
            <CustomToggleButton
              value={Agent.Debugger}
              selected={selectedAgent === Agent.Debugger}
              handleClick={handleAgentSelect}
              sx={{ width: "100%", height: "100%" }}
            >
              <BugReportIcon fontSize="small" />
            </CustomToggleButton>
          </Grid>
        </CustomTooltip>
        <CustomTooltip title="Optimizer">
          <Grid xs={3} item>
            <CustomToggleButton
              value={Agent.Optimizer}
              selected={selectedAgent === Agent.Optimizer}
              handleClick={handleAgentSelect}
              sx={{ width: "100%", height: "100%" }}
            >
              <SpeedIcon fontSize="small" />
            </CustomToggleButton>
          </Grid>
        </CustomTooltip>
        <CustomTooltip title="Cyber Security">
          <Grid xs={3} item>
            <CustomToggleButton
              value={Agent.Cyber_Security}
              selected={selectedAgent === Agent.Cyber_Security}
              handleClick={handleAgentSelect}
              sx={{ width: "100%", height: "100%" }}
            >
              <SecurityIcon fontSize="small" />
            </CustomToggleButton>
          </Grid>
        </CustomTooltip>
      </Grid>

      <Typography variant="body2" color="text.secondary">
        And the starting point of your request
      </Typography>
      <Box
        mt={3}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignContent: "start",
        }}
      >
        <MenuSearch
          onChange={handleSearchChange}
          options={selectedNode ? [...nodeOptions, selectedNode!] : nodeOptions}
          placeholder="Search..."
          loading={loadingNodes}
          value={selectedNode}
          setSelectedNode={setSelectedNode}
          selectedNode={selectedNode}
          selectedRepo={"all"}
        />
        <Paper
          id="file-explorer"
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 1,
            marginTop: 1,
            backgroundColor: "#0B0E12",
            height: "40vh",
            overflowY: "auto",
          }}
        >
          <Typography variant="body2">Explorer</Typography>
          <FileStructure
            selectedNode={selectedNode}
            setSelectedNode={setSelectedNode}
            structure={fileStructure}
            setStructure={setFileStructure}
            loadingCount={loadingFilesCount}
            setLoadingCount={setLoadingFilesCount}
          />
        </Paper>
        <Box display={"flex"} flexDirection={"row"}>
          {selectedChatRoom && (
            <Box sx={{ display: "flex", mt: 1, justifyContent: "flex-start" }}>
              <CustomTooltip
                title={
                  selectedNode
                    ? `Selected Node: ${selectedNode.name}`
                    : "Select a file, class or function as starting node"
                }
              >
                <span>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    id="start-chat-button"
                    disabled={!selectedNode}
                    onClick={() => handleSelectStartingPoint()}
                  >
                    Select starting point
                  </Button>
                </span>
              </CustomTooltip>
            </Box>
          )}

          <Box sx={{ display: "flex", mt: 1, justifyContent: "flex-end" }}>
            <CustomTooltip
              title={
                selectedNode && selectedAgent
                  ? `Selected Node: ${selectedNode.name}`
                  : !selectedAgent
                  ? "Select an agent"
                  : "Select a file, class or function as starting node"
              }
            >
              <span>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  id="start-chat-button"
                  disabled={!selectedNode || !selectedAgent}
                  onClick={() => handleCreateChatRoom()}
                >
                  Create New Chat Room
                </Button>
              </span>
            </CustomTooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NewChatMenu;
