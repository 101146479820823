import { w3cwebsocket } from "websocket";

const RECONNECT_INTERVAL = 5000;
const MAX_RECONNECT_ATTEMPTS = 5;

export const connectWebSocket = (
  url: string,
  onMessage: (event: any) => void,
  onOpen?: (socket: w3cwebsocket | null) => void,
  onError?: (error: any) => void,
  onClose?: (event: any) => void,
  intentionalCloseRef?: React.MutableRefObject<boolean>
): w3cwebsocket => {
  let attempts = 0;
  let socket: w3cwebsocket | null = null;

  const connect = () => {
    socket = new w3cwebsocket(url);

    socket.onopen = () => {
      attempts = 0; // Reset attempts on successful connection
      if (intentionalCloseRef) {
        intentionalCloseRef.current = false; // Reset intentional close flag
      }
      if (onOpen) {
        onOpen(socket);
      }
    };

    socket.onmessage = onMessage;

    socket.onclose = (event) => {
      if (intentionalCloseRef && intentionalCloseRef.current) {
        onClose && onClose(event);
      } else if (attempts < MAX_RECONNECT_ATTEMPTS) {
        setTimeout(() => {
          attempts++;
          if (socket) {
            socket.close(); // Ensure the previous socket is closed before reconnecting
          }
          connect();
        }, RECONNECT_INTERVAL);
      } else {
        console.error("Max reconnect attempts reached");
      }
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
      if (onError) {
        onError(error);
      }
      if (socket) {
        socket.close();
      }
    };

    return socket;
  };

  return connect();
};
