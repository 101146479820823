import { blarClient } from "./Client";

interface BusinessSignUpParams {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  company_name?: string;
  token?: string;
}

export const serverSignIn = async (email: string, password: string) => {
  return blarClient.request("post", "/auth/login/", { email, password });
};

export const serverGuestSignIn = async (email: string ) => {
  return blarClient.request("post", "/auth/login/guest", { email });
};

export const serverRefresh = async (refresh: string) => {
  return blarClient.request("post", "/auth/refresh/", { refresh });
};

export const serverSignUp = async (params: BusinessSignUpParams) => {
  return blarClient.request("post", "/users/register", {
    ...params,
    password2: params.password,
  });
};
