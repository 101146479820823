import { CreateApiKeyParams } from "@/pages/Settings/components/ApiKeys/types";
import { blarClient } from "./Client";

export const create_api_key = async (params: CreateApiKeyParams) => {
  return await blarClient.request("post", `users/api-key/create`, params);
};

export const list_api_keys = async () => {
  return blarClient.request("get", `users/api-key/list?revoked=false`);
};

export const delete_api_key = async (prefix: string) => {
  return blarClient.request("post", `users/api-key/delete`, { prefix });
};
