import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    paddingLeft: "4rem",
    paddingTop: "6rem",
  },
  connectButton: {
    marginBottom: "10%",
    marginLeft: "30px",
  },
  loggerToolBox: {
    marginLeft: "30px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
    width: "450px",
    textAlign: "start",
  },
  repositorySelectContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    width: "100%",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "237px",
  },
});

export default useStyles;
