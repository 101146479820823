import React from "react";
import Box from "@mui/material/Box";
import {
  Chip,
  Grid,
  MenuItem,
  Select,
  Typography,
  SelectChangeEvent,
} from "@mui/material";
import { Circle } from "@mui/icons-material";
import CategoryCheckbox from "./CategoryCheckbox";
import { mapCriticalitiesColors, mapStatusesColors } from "./helpers";
import { ListChatRoomFilters } from "@/interfaces/IChatRoom";
import { Priorities, States } from "../types";

interface FilterMenuProps {
  setFilterAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
  filters: ListChatRoomFilters;
  setFilters: (filters: ListChatRoomFilters) => void;
}

const criticalitiesMap: { [key in Priorities]: string } = {
  critical: "Critical",
  high: "High",
  medium: "Medium",
  low: "Low",
};

const statusMap: { [key in States]: string } = {
  open: "New",
  acknowledged: "Acknowledged",
  closed: "Resolved",
};

const FilterMenu: React.FC<FilterMenuProps> = ({
  setFilterAnchorEl,
  filters,
  setFilters,
}) => {
  const handleCriticalityChange =
    (criticality: Priorities) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newCriticalities = event.target.checked
        ? [...filters.code_error_priority, criticality]
        : filters.code_error_priority.filter((c) => c !== criticality);
      setFilters({ ...filters, code_error_priority: newCriticalities });
    };

  const handleStatusChange =
    (status: States) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newStatuses = event.target.checked
        ? [...filters.code_error_state, status]
        : filters.code_error_state.filter((s) => s !== status);
      setFilters({ ...filters, code_error_state: newStatuses });
    };

  const handleAssignedChange = (
    event: SelectChangeEvent<"all" | "me" | "unassigned">
  ) => {
    setFilters({
      ...filters,
      code_error_assigned_to: event.target.value as "all" | "me" | "unassigned",
    });
  };

  return (
    <Box>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="body2">Assigned</Typography>
          <Select
            size="small"
            fullWidth
            value={filters.code_error_assigned_to}
            onChange={handleAssignedChange}
          >
            <MenuItem value={"all"}>All</MenuItem>
            <MenuItem value={"me"}>Me</MenuItem>
            <MenuItem value={"unassigned"}>Unassigned</MenuItem>
          </Select>
        </Grid>

        <Grid item>
          <Typography variant="body2">Criticality</Typography>
          <Grid container direction="column">
            {Object.keys(criticalitiesMap).map((key) => (
              <Grid item key={key}>
                <CategoryCheckbox
                  checked={filters.code_error_priority.includes(
                    key as Priorities
                  )}
                  onChange={handleCriticalityChange(key as Priorities)}
                >
                  <Chip
                    sx={{ marginRight: "5px" }}
                    label={criticalitiesMap[key as Priorities]}
                    color={mapCriticalitiesColors(key as Priorities)}
                    variant="outlined"
                    size="small"
                  />
                </CategoryCheckbox>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid item>
          <Typography variant="body2">Status</Typography>
          <Grid container direction="column">
            {Object.keys(statusMap).map((key) => (
              <Grid item key={key}>
                <CategoryCheckbox
                  checked={filters.code_error_state.includes(key as States)}
                  onChange={handleStatusChange(key as States)}
                >
                  <Circle
                    color={mapStatusesColors(key as States)}
                    sx={{
                      height: "15px",
                      textAlign: "start",
                      fontSize: "inherit",
                    }}
                  />
                  <Typography ml={1} variant="body2" fontSize="small">
                    {statusMap[key as States]}
                  </Typography>
                </CategoryCheckbox>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FilterMenu;
