import { CheckIntegration } from "@/interfaces/IIntegration";
import {
  AddApiKeyParams,
  SentryOauthParams,
  AddSentryWorkaroundParams,
} from "../../pages/Settings/components/Integrations/types";
import { blarClient } from "./Client";
import { AxiosResponse } from "axios";
import { CodeErrorPut } from "@/pages/Chat/components/types";

export const check_integration_exists = async ({
  source,
}: CheckIntegration) => {
  return blarClient.request("get", `/integrations/${source}`);
};

export const save_api_key = async (params: AddApiKeyParams) => {
  return blarClient.request("post", `/integrations/create`, params);
};

export const save_sentry_workaround = async (
  params: AddSentryWorkaroundParams
) => {
  return blarClient.request("post", `/integrations/sentry/create`, params);
};

export const list_integrations = async () => {
  return blarClient.request("get", `/integrations/list`);
};

export const delete_integration = async (id: string) => {
  return blarClient.request("post", `/integrations/delete`, { id });
};

export const slack_oauth = async () => {
  return blarClient.request("get", `/integrations/slack/oauth/`);
};

export const sentry_oauth = async (payload: SentryOauthParams) => {
  return blarClient.request("post", `/integrations/sentry/oauth`, payload);
};

export const github_oauth = async (payload: {
  code: string;
  state: string;
}): Promise<any> => {
  return blarClient.request("post", `/integrations/github/oauth`, payload);
};

export const github_app_installation = async (payload: {
  installation_id: string;
  code: string;
}) => {
  return blarClient.request(
    "post",
    `/integrations/github/app_installation`,
    payload
  );
};

export const github_list_repos = async () => {
  return blarClient.request("get", `/integrations/github/repositories`);
};

export const github_list_repo_branches = async (repository: string) => {
  return blarClient.request(
    "get",
    `/integrations/github/repositories/branches`,
    undefined,
    { repository }
  );
};

export const github_repository_info = async (
  org: string,
  repo: string
): Promise<AxiosResponse> => {
  return blarClient.request(
    "get",
    `/integrations/github/repositories/${org}/${repo}`
  );
};

export const code_error_put = async (payload: CodeErrorPut) => {
  return blarClient.request("put", `/integrations/code-error`, payload);
};
