import { memo, useEffect, useState } from "react";
import {
  getBezierPath,
  useViewport,
  BaseEdge,
  EdgeLabelRenderer,
  useStore,
} from "reactflow";

import { Typography } from "@mui/material";

const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  label,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data,
}: any) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
  });
  const [opacity, setOpacity] = useState<number>(1);
  const [strokeWidth, setStrokeWidth] = useState<number>(3);
  const [color, setColor] = useState<string>("#b1b1b7");

  const { zoom } = useViewport();
  const zoomSelector = (s: any) => {
    const zoom = s.transform[2];
    const maxZoom =
      0.1 + 0.09 * (Math.max(data.sourceLevel, data.targetLevel) + 1);
    const minZoom =
      0.01 + 0.09 * (Math.min(data.sourceLevel, data.targetLevel) - 1);

    return {
      content: minZoom <= zoom,
      name:
        minZoom + 0.09 <= zoom &&
        (zoom <= maxZoom || data.sourceLeaf || data.targetLeaf),
    };
  };

  const { content: showContent } = useStore(zoomSelector);

  const fontSize = (data.max_level - data.level) ** 1.5 * (1 / zoom);

  useEffect(() => {
    let color = "#b1b1b7";
    if (data.selectedFilter.type === "Latency") {
      if (data.latency > 200) {
        color = "red";
      } else if (data.latency >= 75 && data.latency <= 150) {
        color = "yellow";
      } else if (data.latency < 75 && data.latency > 0) {
        color = "green";
      }
    }
    if (data.highlightedEdges.includes(id)) {
      color = "#4C4C4C95";
      setStrokeWidth(7);
    }
    setColor(color);
  }, [data.selectedFilter, data.latency]);

  return (
    <>
      {showContent && (
        <>
          <BaseEdge
            id={id}
            style={{ ...style, opacity, strokeWidth, stroke: color }}
            path={edgePath}
            markerEnd={markerEnd}
          />
          <EdgeLabelRenderer>
            <Typography
              variant="h6"
              sx={{
                position: "absolute",
                transform: `translate(-50%, -50%) translate(${labelX}px, ${labelY}px)`,
                fontSize,
              }}
            >
              {label}
            </Typography>
          </EdgeLabelRenderer>
        </>
      )}
    </>
  );
};

export default memo(CustomEdge);
