import { TableRowData } from "@/components/Tables/types";
import { ApiKey, ApiKeyPaginatedResponse } from "./types";

export function convertApiKeysToRow(
  data: ApiKeyPaginatedResponse
): TableRowData[] {
  return data.results.map((apiKey: ApiKey) => ({
    _prefix: { content: apiKey.prefix, type: "hidden" },
    Name: { content: apiKey.name, type: "string" },
    "API Key": {
      content: apiKey.prefix + ".****",
      type: "string",
    },
    "Created At": {
      content: new Date(apiKey.created),
      type: "date",
    },
    Active: { content: !apiKey.revoked, type: "boolean" },
  }));
}
