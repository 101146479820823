import React from "react";
import { Typography, Box, Tooltip } from "@mui/material";

import UserAvatar from "@/components/User/UserAvatar";

import { IUserAvatar } from "@/components/User/types";

interface AssignMenuProps {
  users: IUserAvatar[];
}

const UserAvatars: React.FC<AssignMenuProps> = ({ users }) => {
  // Limit the number of displayed avatars
  const displayedUsers = users.slice(0, 4);
  const extraUserCount = users.length - 4;

  return (
    <Box display="flex" alignItems="center">
      {/* Render avatars in line */}
      {displayedUsers.map((user, index) => (
        <Tooltip key={index} title={user.email}>
          <Box key={index} mr={1}>
            <UserAvatar user={user} />
          </Box>
        </Tooltip>
      ))}

      {/* Display '...' if there are more users */}
      {extraUserCount > 0 && (
        <Typography variant="body2">+{extraUserCount}...</Typography>
      )}
    </Box>
  );
};

export default UserAvatars;
