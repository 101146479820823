import React from 'react';
import { Box } from '@mui/material';
import { styled, keyframes } from '@mui/system';

const bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
`;

const Dot = styled('div')`
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 50%;
  display: inline-block;
  animation: ${bounce} 1.4s infinite ease-in-out both;
`;

const Dot1 = styled(Dot)`
  animation-delay: -0.32s;
`;

const Dot2 = styled(Dot)`
  animation-delay: -0.16s;
`;

const LoadingDots: React.FC = () => (
  <Box display="flex" justifyContent="center" alignItems="center">
    <Dot1 />
    <Dot2 />
    <Dot />
  </Box>
);

export default LoadingDots;
