import { Priorities, States } from "../types";

export const mapCriticalitiesColors = (criticality: Priorities) => {
  switch (criticality) {
    case "critical":
      return "blarCritical";
    case "high":
      return "blarHigh";
    case "medium":
      return "blarWarning";
    case "low":
      return "blarSuccess";
    default:
      return "primary";
  }
};

export const mapStatusesColors = (status: States) => {
  switch (status) {
    case "open":
      return "blarCritical";
    case "acknowledged":
      return "blarWarning";
    case "closed":
      return "blarSuccess";
    default:
      return "primary";
  }
};
