import axios, {
  AxiosInstance,
  InternalAxiosRequestConfig,
  AxiosResponse,
  AxiosRequestConfig,
} from "axios";
import { ContextProps } from "../../ContextProvider";
import Cookies from "universal-cookie";

class BlarClient {
  private static instance: BlarClient;
  private axiosInstance: AxiosInstance | undefined = undefined;
  private API_BASE_URL = process.env.REACT_APP_API_URL!;
  private cookies = new Cookies();
  private signOut?: ContextProps["signOut"];

  constructor() {
    if (BlarClient.instance) {
      return BlarClient.instance;
    }

    this.axiosInstance = axios.create({
      baseURL: this.API_BASE_URL,
      timeout: 120000,
    });

    this.axiosInstance.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        const access = this.cookies.get("access");
        if (access) {
          config.headers["Authorization"] = `Bearer ${access}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.axiosInstance.interceptors.response.use((response: AxiosResponse) => {
      return response;
    }, this.handleError.bind(this));

    BlarClient.instance = this;
  }

  init(signOutFunction: ContextProps["signOut"]): void {
    this.signOut = signOutFunction;
  }

  request(
    method: "get" | "post" | "put" | "delete" | "patch" | "sse",
    url: string,
    params?: any,
    queryParams?: any,
    headers?: any
  ): Promise<AxiosResponse> {
    const config: AxiosRequestConfig = {
      headers,
      params: queryParams,
      paramsSerializer: {
        indexes: null,
      },
    };
    switch (method) {
      case "get":
        return this.axiosInstance!.get(url, config);
      case "post":
        return this.axiosInstance!.post(url, params, config);
      case "put":
        return this.axiosInstance!.put(url, params, config);
      case "patch":
        return this.axiosInstance!.patch(url, params, config);
      case "delete":
        return this.axiosInstance!.delete(url, config);
      case "sse":
        config.headers = {
          "Content-Type": "application/json",
          Accept: "text/event-stream",
        };
        config.responseType = "stream";
        config.adapter = "fetch";
        return this.axiosInstance!.post(url, params, config);
      default:
        throw new Error("No valid method");
    }
  }

  private async handleError(error: any): Promise<any> {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 403 &&
      !originalRequest._retry &&
      originalRequest.url !== "user/signin"
    ) {
      
      const errorReason = error.response.data.detail;
      
      if (errorReason === "You do not have permission to perform this action.") {
        return Promise.reject(error);
      }

      originalRequest._retry = true;
      const refreshToken = this.cookies.get("refresh");

      if (!refreshToken) {
        return Promise.reject(error);
      }

      try {
        const { data } = await axios.post(
          `${this.API_BASE_URL}/auth/refresh/`,
          { refresh: refreshToken }
        );
        this.cookies.set("access", data.access, {
          secure: true,
        });

        // Retry the original request
        originalRequest.headers["Authorization"] = "Bearer " + data.access;
        return this.axiosInstance!(originalRequest);
      } catch (refreshError: any) {
        console.error("Error refreshing token", refreshError);
        if (refreshError.response && refreshError.response.status === 401) {
          if (this.signOut) this.signOut();
        }
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
}

export const blarClient = new BlarClient();
