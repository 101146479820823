import { useState, useContext, useEffect, useRef } from "react";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";
import ErrorIcon from "@mui/icons-material/Error";
import { Context } from "../../../../../ContextProvider";
import { checkRepoState, sync_repo } from "@/services/Blar/Repo_graph";
import { TaskState } from "@/types/common";

type TableSyncIconProps = {
  repoId: string;
  initialState: TaskState;
};

const TableSyncIcon = ({ repoId, initialState }: TableSyncIconProps) => {
  const [loading, setLoading] = useState(false);
  const { showMessage, setPoll } = useContext(Context);
  const [state, setState] = useState<TaskState>(initialState);
  const [hover, setHover] = useState(false);
  const intervalRef = useRef<NodeJS.Timer | null>(null);

  const handleSync = async () => {
    setLoading(true);
    try {
      await sync_repo({
        repo_id: repoId,
      });
      showMessage("success", "Repo scheduled to sync");
      setState("pending");
    } catch (error) {
      showMessage("error", "Error syncing Repo");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const pollTaskStatus = (repoId: string) => {
      if (intervalRef.current) clearInterval(intervalRef.current);
      intervalRef.current = setInterval(async () => {
        try {
          const response = await checkRepoState(repoId);
          const state = response.data.task_state;
          switch (state) {
            case "completed":
              showMessage("success", "Repo synced successfully");
              clearInterval(intervalRef.current!);
              setState(state);
              setHover(false);
              break;
            case "failed":
              showMessage("error", "Error syncing Repo");
              setState(state);
              clearInterval(intervalRef.current!);
              setHover(false);
              break;
            default:
              break;
          }
        } catch (error) {
          console.error("Error fetching task status:", error);
          showMessage("error", "Error checking task status");
        }
      }, 5000); // Poll every 5 seconds
    };

    if (state === "in_progress" || state === "pending") {
      setLoading(true);
      setPoll(Date.now());
      pollTaskStatus(repoId);
    } else {
      setLoading(false);
    }
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, repoId]);

  return (
    <>
      {loading ? (
        <CircularProgress size={20} />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{ display: "flex", alignItems: "center" }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            {state === "failed" ? (
              <Tooltip title="Something failed while syncing the repo. Please try again.">
                <IconButton onClick={handleSync}>
                  {hover ? (
                    <SyncIcon fontSize="small" sx={{ color: "#1a7f64" }} />
                  ) : (
                    <ErrorIcon fontSize="small" sx={{ color: "#cc3300" }} />
                  )}
                </IconButton>
              </Tooltip>
            ) : (
              <IconButton onClick={handleSync}>
                <SyncIcon fontSize="small" sx={{ color: "#1a7f64" }} />
              </IconButton>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default TableSyncIcon;
