import React from "react";
import { Checkbox, Box, CheckboxProps } from "@mui/material";
import { styled } from "@mui/system";
import CheckIcon from "@mui/icons-material/Check";

interface CategoryCheckboxProps {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
}

const CustomCheckbox = styled((props: CheckboxProps) => (
  <Checkbox {...props} />
))(({ theme }) => ({
  "&.MuiCheckbox-root": {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  "& .MuiSvgIcon-root": {
    border: "1px solid #E8EFF5",
    borderRadius: "5px",
    fill: "transparent",
  },
  "&.Mui-checked": {
    "& .MuiSvgIcon-root": {
      fill: "#E8EFF5", // White color for the checkmark
    },
    "&:hover": {
      backgroundColor: "transparent", // Ensure background remains transparent when checked
    },
  },
}));

const CategoryCheckbox: React.FC<CategoryCheckboxProps> = ({
  checked,
  onChange,
  children,
}) => {
  return (
    <Box display="flex" alignItems="center">
      <CustomCheckbox
        size="small"
        checkedIcon={<CheckIcon></CheckIcon>}
        checked={checked}
        onChange={onChange}
      />
      {children}
    </Box>
  );
};

export default CategoryCheckbox;
