import React, { FC, useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/prism";

interface CodeDisplayProps {
  code: string;
  width: number | string;
  height: number | string;
}

const CodeDisplay: FC<CodeDisplayProps> = ({ code, width, height }) => {
  return (
    <SyntaxHighlighter
      customStyle={{
        width,
        height,
      }}
      language="python"
      style={dracula}
    >
      {code}
    </SyntaxHighlighter>
  );
};

export default CodeDisplay;
