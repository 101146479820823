import { Grid } from "@mui/material";
import IntegrationCard from "./IntegrationCard";
import { integrations } from "./integrations";
import BaseModal from "@/components/Modal/BaseModal";
type AddIntegrationModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  integrated: string[];
  setCreatedObject: (createdObject: number) => void;
  selectedIntegration: string | null;
};

const AddIntegrationModal = ({
  open,
  setOpen,
  integrated,
  setCreatedObject,
  selectedIntegration,
}: AddIntegrationModalProps) => {
  const handleClose = () => setOpen(false);

  return (
    <>
      <BaseModal
        open={open}
        onClose={handleClose}
        title="Add Integration"
        style={{ width: "70%" }}
      >
        <Grid container spacing={2}>
          {integrations.map((integration, index) => (
            <Grid item xs={3} key={index}>
              <IntegrationCard
                integration={integration}
                connected={integrated.includes(integration.dbName)}
                setCreatedObject={setCreatedObject}
                selectedIntegration={selectedIntegration === integration.dbName}
              />
            </Grid>
          ))}
        </Grid>
      </BaseModal>
    </>
  );
};

export default AddIntegrationModal;
