import { useEffect, useState, useContext } from "react";
import { Typography, Grid, IconButton } from "@mui/material";
import { TableRowData } from "../../../../components/Tables/types";

import TableWithActions from "../../../../components/Tables/TableWithActions";
import AddIcon from "@mui/icons-material/Add";
import AddIntegrationModal from "../../../../components/Integrations/AddIntegrationModal";
import {
  delete_integration,
  list_integrations,
} from "../../../../services/Blar/Integrations";
import { convertIntegrationsToRow } from "./helper";
import { IntegrationPaginatedResponse } from "./types";
import { Context } from "../../../../ContextProvider";

const defaultIntegrationsTitles = ["Name", "API Key", "Active", "Created At"];

type IntegrationTableProps = {
  selectedIntegration: string | null;
};

const IntegrationTable = ({ selectedIntegration }: IntegrationTableProps) => {
  const [openAddIntegration, setOpenAddIntegration] = useState(
    selectedIntegration ? true : false
  );
  const [integrationsRow, setIntegrationsRow] = useState<TableRowData[]>([]);
  const [integrated, setIntegrated] = useState<string[]>([]);
  const [createdObject, setCreatedObject] = useState(Date.now());
  const [loading, setLoading] = useState<boolean>(false);
  const { showMessage } = useContext(Context);

  useEffect(() => {
    async function fetchIntegrationData() {
      try {
        setLoading(true);
        const integrationsResult = (await list_integrations())
          .data as IntegrationPaginatedResponse;
        setIntegrated(
          integrationsResult.results
            .filter((integration) => integration.active)
            .map((integration) => integration.source)
        );
        const integrationRows = convertIntegrationsToRow(integrationsResult);
        setIntegrationsRow(integrationRows);
      } catch (error) {
        showMessage("error", `Failed to fetch integrations`);
      } finally {
        setLoading(false);
      }
    }
    fetchIntegrationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdObject]);

  const handleIntegrationDelete = async (index: number) => {
    try {
      await delete_integration(integrationsRow[index]._id.content as string);
      setCreatedObject(Date.now());
      showMessage("success", "Integration deleted successfully");
    } catch (error) {
      showMessage("error", "Error deleting integration");
    }
  };

  const handleAddIntegration = () => {
    setOpenAddIntegration(true);
  };

  return (
    <>
      <Grid container alignItems="center" sx={{ marginBottom: "20px" }}>
        <Grid item>
          <Typography variant="h6">Integrations</Typography>
        </Grid>
        <Grid item>
          <IconButton
            sx={{
              border: "1px solid #FFFFFF4D",
              color: "gray",
              marginLeft: "10px",
            }}
            size="small"
            onClick={handleAddIntegration}
          >
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>
      <TableWithActions
        loading={loading}
        tableRows={integrationsRow}
        onDelete={handleIntegrationDelete}
        defaultTitles={defaultIntegrationsTitles}
      />

      <AddIntegrationModal
        open={openAddIntegration}
        setOpen={setOpenAddIntegration}
        integrated={integrated}
        setCreatedObject={setCreatedObject}
        selectedIntegration={selectedIntegration}
      />
    </>
  );
};

export default IntegrationTable;
