import React from "react";
import { Typography, Button } from "@mui/material";

import { Panel } from "reactflow";

import GraphVideoStepPanel from "./GraphVideo/GraphVideoStepPanel";
import InfoPanel from "./InfoPanel/InfoPanel";
import { GetNodeOutput } from "@/interfaces/IBackendOutputs";

interface FilterPanelProps {
  setHightLightNode: (nodeId: string | undefined) => void;
  FetchNodeDetail: (nodeId: string) => void;
  nodeInfoToShow?: GetNodeOutput;
  loadingNodeInfo: boolean;
  selectedGraphVideo: number | null;
  setHighlightedPath: (path: string[]) => void;
  handleGoBack: () => void;
}

const FilterPanel: React.FC<FilterPanelProps> = ({
  setHightLightNode,
  FetchNodeDetail,
  nodeInfoToShow,
  loadingNodeInfo,
  selectedGraphVideo,
  setHighlightedPath,
  handleGoBack,
}) => {
  return (
    <>
      <Panel position="top-left">
        {selectedGraphVideo !== null && (
          <Button
            variant="outlined"
            onClick={handleGoBack}
            sx={{ color: "black" }}
            color="blarBlack"
          >
            <Typography
              variant="body1"
              sx={{ marginLeft: 1, marginRight: 1, color: "black" }}
            >
              Go back to the report
            </Typography>
          </Button>
        )}
      </Panel>
      <Panel position="bottom-right" style={{ width: "25%" }}>
        <div style={{ marginBottom: "10px" }}>
          {(nodeInfoToShow || loadingNodeInfo) && (
            <InfoPanel
              nodeInfoToShow={nodeInfoToShow}
              loading={loadingNodeInfo}
              setHightLightNode={setHightLightNode}
              height={selectedGraphVideo !== null ? "30vh" : "50vh"}
            />
          )}
        </div>
        {selectedGraphVideo !== null && (
          <GraphVideoStepPanel
            selectedGraphVideo={selectedGraphVideo}
            setHightLightNode={setHightLightNode}
            FetchNodeDetail={FetchNodeDetail}
            setHighlightedPath={setHighlightedPath}
            height="55vh"
          ></GraphVideoStepPanel>
        )}
      </Panel>
    </>
  );
};

export default FilterPanel;
