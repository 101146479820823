import React, { useState, useEffect, useContext } from "react";
import { Grid, Box, Typography, CircularProgress } from "@mui/material";
import AuthForm from "./AuthForm";
import GraphDBConfiguration from "./Onboarding/GraphDBConfiguration";
import CoreIntegrationComponent from "./Onboarding/CoreIntegrations";
import NotificationsIntegrationComponent from "./Onboarding/NotificationIntegrations";
import ReadyToGoComponent from "./Onboarding/ReadyToGo";

import useStyles from "./styles/Onboarding";
import { useNavigate } from "react-router-dom";
import { Context } from "@/ContextProvider";
import { List } from "lodash";
import { update_company } from "@/services/Blar/Company";
import { useTheme } from "@mui/material/styles";

const onbardingSteps: List<React.FC<any>> = [
  AuthForm,
  GraphDBConfiguration,
  CoreIntegrationComponent,
  NotificationsIntegrationComponent,
  ReadyToGoComponent,
];

const Onboarding: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { initialOnboardingStep, showMessage, setIsOnboarding } =
    useContext(Context);
  const onboardingContainerRef = React.useRef<HTMLDivElement>(null);
  const [imageHeight, setImageHeight] = useState<number | null>(null);
  const [onboardingStep, setOnboardingStep] = useState<number | null>(null);
  const theme = useTheme();

  useEffect(() => {
    if (initialOnboardingStep) {
      setOnboardingStep(initialOnboardingStep);
      return;
    }
    setOnboardingStep(0);
  }, [initialOnboardingStep]);

  useEffect(() => {
    if (onboardingContainerRef.current) {
      setImageHeight(onboardingContainerRef.current.offsetHeight);
    }
  }, [onboardingContainerRef.current?.offsetHeight, onboardingStep]);

  const continueOnboarding = async () => {
    if (onboardingStep === onbardingSteps.length - 1) {
      // Finish Onboarding
      try {
        await update_company({
          finished_onboarding: true,
        });
        setIsOnboarding(false);
        navigate("/chat?tutorial=true");
      } catch (error) {
        showMessage("error", "Network error");
      }
      return;
    }
    try {
      await update_company({ onboarding_step: onboardingStep! + 1 });
      setOnboardingStep(onboardingStep! + 1);
    } catch (error) {
      showMessage("error", "Network error");
    }
  };

  return (
    <Box className={classes.root}>
      {onboardingStep !== null ? (
        <Grid container>
          <Grid
            item
            container
            direction={"column"}
            xs={6}
            sx={{ background: theme.palette.background.paper }}
            className={classes.leftSection}
          >
            <Box className={classes.logo}>
              <img src="/logos/WhiteLogo.svg" alt="Blar" />
              <img src="/logos/BlarLetters.svg" alt="Blar" />
            </Box>
            <Box className={classes.slogan}>
              <Typography
                variant="h3"
                align="right"
                sx={{
                  lineHeight: "70px",
                  paddingTop: "50px",
                }}
              >
                Let's spend less time
              </Typography>
              <Typography
                variant="h3"
                align="right"
                sx={{
                  lineHeight: "70px",
                }}
              >
                dealing with bugs and
              </Typography>
              <Typography
                variant="h3"
                align="right"
                sx={{
                  lineHeight: "70px",
                }}
              >
                more time building!
              </Typography>
            </Box>
            <Box ref={onboardingContainerRef} className={classes.stepsBox}>
              {imageHeight && (
                <img
                  style={{ height: imageHeight - 100 }}
                  src={`/onboarding/${onboardingStep + 1}.svg`}
                  alt="Onboarding"
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={6} className={classes.rightSection}>
            {React.createElement(onbardingSteps[onboardingStep], {
              continueOnboarding,
            })}
          </Grid>
        </Grid>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <CircularProgress size={80} />
        </Box>
      )}
    </Box>
  );
};

export default Onboarding;
