// SideAlert.tsx
import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { AlertColor } from '@mui/material';

interface SideAlertProps {
  open: boolean;
  message: string;
  severity: AlertColor;
  onClose: () => void;
}

const SideAlert: React.FC<SideAlertProps> = ({ open, severity, message, onClose }) => {
  return (
    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={6000} onClose={onClose}>
      <Alert onClose={onClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export default SideAlert;
