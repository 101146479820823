import { TableRowData } from "@/components/Tables/types";
import { Query, QueryPaginatedResponse } from "./types"

export function convertQueriesToRow(
  data: QueryPaginatedResponse
): TableRowData[] {
  return data.results.map((query: Query) => ({
    Query: { content: query.query.replace(/\n/g, "\n"), type: "string" },
    "Created At": {
      content: new Date(query.created_at),
      type: "date",
      options: { dateFormat: "MM/dd/yy HH:mm:SS" },
    },
  }));
}
