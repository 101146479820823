import { useEffect, useState, useContext } from "react";
import {
  TableRowData,
  TablePaginationOptions,
} from "@/components/Tables/types";

import TableWithActions from "@/components/Tables/TableWithActions";
import { convertQueriesToRow } from "./helper";
import { QueryPaginatedResponse } from "./types";
import { Context } from "../../ContextProvider";
import { list_queries } from "@/services/Blar/Queries";
import { Box } from "@mui/material";

const defaultQueriesTitles = ["Query", "Created At"];
const ROWS_PER_PAGE = 10;

const QueriesTable = () => {
  const [queriesRow, setQueriesRow] = useState<TableRowData[]>([]);
  const [page, setPage] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const [loading, setLoading] = useState<boolean>(false);
  const { showMessage } = useContext(Context);

  const handleChangePage = async (event: unknown, newPage: number) => {
    const offset = newPage * rowsPerPage;
    const queriesResult = (await list_queries(offset, rowsPerPage))
      .data as QueryPaginatedResponse;
    setQueriesRow(convertQueriesToRow(queriesResult));
    setPage(newPage);
    setCount(queriesResult.count);
    return convertQueriesToRow(queriesResult);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
  };

  const tablePagination: TablePaginationOptions = {
    page: page,
    rowsPerPage: rowsPerPage,
    count: count,
    handlePagination: handleChangePage,
    handleChangeRowsPerPage: handleChangeRowsPerPage,
  };

  useEffect(() => {
    handleChangePage({}, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage]);

  useEffect(() => {
    async function fetchQueriesData() {
      try {
        setLoading(true);
        const reposResult = (await list_queries(0, ROWS_PER_PAGE))
          .data as QueryPaginatedResponse;
        setCount(reposResult.count);
        const queryRows = convertQueriesToRow(reposResult);
        setQueriesRow(queryRows);
      } catch (error) {
        showMessage("error", `Failed to fetch repos`);
      } finally {
        setLoading(false);
      }
    }

    fetchQueriesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      width={"100%"}
    >
      <Box margin={"5%"}>
        <TableWithActions
          loading={loading}
          tableRows={queriesRow}
          defaultTitles={defaultQueriesTitles}
          tablePagination={tablePagination}
        />
      </Box>
    </Box>
  );
};

export default QueriesTable;
