import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  chatContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  chooseAgentContainer: {
    width: "228px",
    height: "187px",
    border: "1px solid",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
  },
  messageContainer: {
    height: "100%",
    width: "100%",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingRight: "8%",
    paddingLeft: "8%",
    marginBottom: "15px",
    alignItems: "center",
  },
  messageList: {
    height: "100%",
    width: "100%",
    flexGrow: 1,
    boxSizing: "border-box",
  },
  bottomBarContainer: {
    width: "80%",
    maxHeight: "25%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexShrink: 0,
  },
  textFieldContainer: {
    width: "80%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexShrink: 0,
  },
  message: {
    padding: "10px 15px !important",
    margin: "5px 0",
    alignSelf: "flex-start",
    maxWidth: "80%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  timestamp: {
    padding: "4px",
    alignSelf: "flex-end",
  },
  copyButton: {
    position: "absolute",
    top: "5px",
    padding: "5px",
    right: 0,
  },
  avatar: {
    margin: "8px",
  },
});

export default useStyles;
