import React, { useEffect, useState } from "react";
import {
  Box,
  Collapse,
  LinearProgress,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  DataObjectOutlined,
  Folder,
  FolderOpen,
  InsertDriveFile,
  InsertDriveFileOutlined,
} from "@mui/icons-material";
import { FileNode } from "../types";
import { get_node_hierarchy } from "@/services/Blar/Repo_graph";
import { useTheme } from '@mui/material/styles';

interface FileStructureProps {
  structure: FileNode[];
  setStructure: React.Dispatch<React.SetStateAction<FileNode[]>>;
  selectedNode: FileNode | null;
  setSelectedNode: React.Dispatch<React.SetStateAction<FileNode | null>>;
  loadingCount: number;
  setLoadingCount: React.Dispatch<React.SetStateAction<number>>;
}

const FileStructure: React.FC<FileStructureProps> = ({
  structure,
  setStructure,
  setSelectedNode,
  selectedNode,
  loadingCount,
  setLoadingCount,
}) => {
  const theme = useTheme();
  const [openFolders, setOpenFolders] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(loadingCount > 0);
  }, [loadingCount]);

  const getIcon = (labels: string[], currentPath: string) => {
    const sx = {
      width: "17px", 
      height: "17px" 
    }
    if (labels.includes("PACKAGE") || labels.includes("FOLDER")) {
      return openFolders[currentPath] ? <FolderOpen sx={sx} /> : <Folder sx={sx} />;
    } else if (labels.includes("FILE")) {
      return openFolders[currentPath] ? (
        <InsertDriveFileOutlined sx={sx} />
      ) : (
        <InsertDriveFile sx={sx} />
      );
    } else if (labels.includes("CLASS")) {
      return <DataObjectOutlined sx={sx} />;
    } else if (labels.includes("FUNCTION")) {
      return (
        <img
          src="/icons/function-icon-white.png"
          alt="Function Icon"
          style={{ color: "#FFFFFF", width: "12px", height: "12px" }}
        />
      );
    }
  };

  const addPathToChildren = (node: FileNode, object_path: number[] = []) => {
    node.object_path = object_path; // Set the object_path for the current node

    if (node.children) {
      node.children.forEach((child, index) => {
        // Recursively set the object_path for each child
        return addPathToChildren(child, [...object_path, index]);
      });
    }
    return node;
  };

  function replaceNodeAtPath(
    data: FileNode[],
    object_path: number[],
    newNode: FileNode
  ) {
    const cloneData = JSON.parse(JSON.stringify(data)); // Deep clone to avoid mutating the original data

    let currentLevel = cloneData;

    for (let i = 0; i < object_path.length - 1; i++) {
      const index = object_path[i];
      if (index >= currentLevel.length) {
        throw new Error("Path index out of bounds");
      }
      currentLevel = currentLevel[index].children || [];
    }

    const finalIndex = object_path[object_path.length - 1];
    if (finalIndex >= currentLevel.length) {
      throw new Error("Path index out of bounds");
    }

    const currentLevelPath = currentLevel[finalIndex].object_path;
    newNode = addPathToChildren(newNode, currentLevelPath);
    currentLevel[finalIndex].fetched = true;
    currentLevel[finalIndex].children = newNode.children;

    setStructure(cloneData);
  }

  function shouldFetch(structure: FileNode[], object_path: number[]) {
    let currentLevel = structure;
    for (let i = 0; i < object_path.length - 1; i++) {
      const index = object_path[i];
      if (index >= currentLevel.length) {
        throw new Error("Path index out of bounds");
      }
      currentLevel = currentLevel[index].children || [];
    }
    const finalIndex = object_path[object_path.length - 1];
    if (finalIndex >= currentLevel.length) {
      throw new Error("Path index out of bounds");
    }
    const node = currentLevel[finalIndex];

    //fetched, don't fetch
    return !node.fetched;
  }

  const handleToggle = async (object_path: string, node: FileNode) => {
    setLoadingCount((prev) => prev + 1);

    setOpenFolders((prevState) => ({
      ...prevState,
      [object_path]: !prevState[object_path],
    }));
    if (
      node.type.includes("FILE") ||
      node.type.includes("CLASS") ||
      node.type.includes("FUNCTION")
    ) {
      setSelectedNode(node);
    }

    try {
      if (shouldFetch(structure, node.object_path || [])) {
        const response = await get_node_hierarchy(node.node_id);
        const data = response.data;
        replaceNodeAtPath(structure, node.object_path || [], data[0]);
      }
    } catch (error) {
      console.error("Error loading JSON:", error);
    } finally {
      setLoadingCount((prev) => prev - 1);
    }
  };

  const renderFileTree = (
    nodes: FileNode[],
    path: string = "",
    level: number = 0
  ): React.ReactNode => {
    return nodes.map((node) => {
      const currentPath = `${path}/${node.name}`;
      return (
        <Box key={currentPath}>
          <ListItemButton
            onClick={() => handleToggle(currentPath, node)}
            sx={{
              pl: level * 2,
              backgroundColor:
                selectedNode?.node_id === node.node_id ? theme.palette.divider : "",
            }}
          >
            <ListItemIcon>{getIcon(node.type, currentPath)}</ListItemIcon>
            <ListItemText primaryTypographyProps={{ fontSize: 13}} primary={node.name} />
          </ListItemButton>
          <Collapse in={openFolders[currentPath]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {renderFileTree(node.children!, currentPath, level + 1)}
            </List>
          </Collapse>
        </Box>
      );
    });
  };

  return (
    <Box display={"flex"} flexDirection={"column"}>
      {loading && (
        <LinearProgress sx={{ position: "relative", top: 0, width: "100%" }} />
      )}
      <List>{renderFileTree(structure)}</List>
    </Box>
  );
};

export default FileStructure;
