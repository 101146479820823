import React from "react";
import { Box, Divider, Link, Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CustomToggleButton from "@/components/Buttons/ToggleButton";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

const ProductDescription: React.FC = () => {
  const theme = useTheme();

  return (
    <>
      <Typography variant="body1" textAlign={"center"} mb={2}>
        Learn more about the different types of interactions in Blar
      </Typography>
      <Paper
        sx={{
          borderColor: theme.palette.divider,
          maxWidth: "70%",
          padding: 2,
        }}
      >
        <Box display={"flex"} flexDirection={"row"}>
          <Box width={"50%"}>
            <Box display="flex" flexDirection="row" alignItems="center" mb={2}>
              <CustomToggleButton
                handleClick={() => {}}
                value=""
                selected={false}
              >
                <img
                  src="/integrations/SentryIcon.svg"
                  alt="Logo"
                  style={{ width: 17, height: 15 }}
                />
              </CustomToggleButton>
              <Typography variant="body1" textAlign="start" ml={2}>
                Automatically Capture Errors
              </Typography>
            </Box>
            <Box ml={3}>
              <Typography variant="body2" textAlign={"start"} mb={2}>
                1. Connect to Sentry or New Relic{" "}
              </Typography>
              <Typography variant="body2" textAlign={"start"} mb={2}>
                2. Set up an alert in their platform
              </Typography>
              <Typography variant="body2" textAlign={"start"} mb={4}>
                3. Let Blar begin identifying the root cause of these alerts
              </Typography>
              <Typography variant="body2" textAlign={"start"} mb={2}>
                Discover more about integrations at{" "}
                {
                  <Link
                    href="https://blar.io/docs"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    blar.io/docs
                  </Link>
                }
              </Typography>
            </Box>
          </Box>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ marginRight: "10px", marginLeft: "10px" }}
          />
          <Box width={"50%"}>
            <Box display="flex" flexDirection="row" alignItems="center" mb={2}>
              <CustomToggleButton
                handleClick={() => {}}
                value=""
                selected={false}
              >
                <AccountCircleOutlinedIcon
                  sx={{ width: 20, height: 20, color: "primary" }}
                />
              </CustomToggleButton>
              <Typography variant="body1" textAlign="start" ml={2}>
                Manually Create Requests
              </Typography>
            </Box>
            <Box ml={3}>
              <Typography variant="body2" textAlign={"start"} mb={2}>
                1. Create a new request (
                {<img src="/icons/edit_square.svg" alt="Logo" />})
              </Typography>
              <Typography variant="body2" textAlign={"start"} mb={2}>
                2. Choose the agent you need—Blar can help with debugging,
                boosting performance, or tackling cybersecurity issues
              </Typography>
              <Typography variant="body2" textAlign={"start"} mb={2}>
                3. Pick a starting point in your code
              </Typography>
              <Typography variant="body2" textAlign={"start"} mb={2}>
                4. Start chatting with Blar
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default ProductDescription;
