import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CustomToggleButton from "@/components/Buttons/ToggleButton";
import { Agent, FileNode } from "../../types";
import BugReportIcon from "@mui/icons-material/BugReport";
import SpeedIcon from "@mui/icons-material/Speed";
import SecurityIcon from "@mui/icons-material/Security";
import EngineeringIcon from "@mui/icons-material/Engineering";

interface AgentDescriptionProps {
  selectedNode: FileNode | undefined | null;
  selectedAgent: Agent | "debugger" | "optimizer" | "cyber_security";
}

const AgentDescription: React.FC<AgentDescriptionProps> = ({
  selectedNode,
  selectedAgent,
}) => {
  const theme = useTheme();

  const getAgentIcon = (
    agent: Agent | "debugger" | "optimizer" | "cyber_security" | "general"
  ) => {
    switch (agent) {
      case Agent.Debugger:
        return <BugReportIcon />;
      case Agent.Optimizer:
        return <SpeedIcon />;
      case Agent.Cyber_Security:
        return <SecurityIcon />;
      case Agent.General:
        return <EngineeringIcon />;
      default:
        return null;
    }
  };

  const getAgentTitle = (
    agent: Agent | "debugger" | "optimizer" | "cyber_security" | "general"
  ) => {
    switch (agent) {
      case Agent.Debugger:
        return "Debugger";
      case Agent.Optimizer:
        return "Optimizer";
      case Agent.Cyber_Security:
        return "Cyber Security";
      case Agent.General:
        return "General (Beta)";
      default:
        return "";
    }
  };

  const getAgentDescription = (
    agent: Agent | "debugger" | "optimizer" | "cyber_security" | "general"
  ) => {
    switch (agent) {
      case Agent.Debugger:
        return (
          <>
            <Typography variant="body2" textAlign={"start"} mb={2}>
              1. Clearly outline the issue you're encountering, including
              symptoms and error messages.
            </Typography>
            <Typography variant="body2" textAlign={"start"} mb={2}>
              2. Provide any relevant context, such as recent changes, logs, or
              steps to reproduce the issue.
            </Typography>
            <Typography variant="body2" textAlign={"start"} mb={2}>
              3. Let Blar analyze the details to pinpoint the root cause and
              suggest solutions.
            </Typography>
          </>
        );
      case Agent.Optimizer:
        return (
          <>
            <Typography variant="body2" textAlign={"start"} mb={2}>
              1. Specify the performance goals or areas of concern in your code.
            </Typography>
            <Typography variant="body2" textAlign={"start"} mb={2}>
              2. Share any relevant metrics, logs, or context to help the agent
              assess the situation.
            </Typography>
            <Typography variant="body2" textAlign={"start"} mb={2}>
              3. Let Blar assist you in enhancing efficiency and optimizing your
              code.
            </Typography>
          </>
        );
      case Agent.Cyber_Security:
        return (
          <>
            <Typography variant="body2" textAlign={"start"} mb={2}>
              1. Define the assets or areas you want to secure, highlighting any
              critical components.
            </Typography>
            <Typography variant="body2" textAlign={"start"} mb={2}>
              2. Provide context about potential vulnerabilities or areas that
              require extra attention.
            </Typography>
            <Typography variant="body2" textAlign={"start"} mb={2}>
              3. Let Blar identify and help you address security risks in your
              code.
            </Typography>
          </>
        );
      case Agent.General:
        return (
          <>
            <Typography variant="body2" textAlign={"start"} mb={2}>
              1. Describe your general query or problem in detail.
            </Typography>
            <Typography variant="body2" textAlign={"start"} mb={2}>
              2. Provide any relevant context or background information that
              might help in understanding the issue.
            </Typography>
            <Typography variant="body2" textAlign={"start"} mb={2}>
              3. Let Blar assist you with a comprehensive answer, utilizing its
              ability to traverse the code graph and gather relevant
              information.
            </Typography>
          </>
        );
      default:
        return <></>;
    }
  };

  function formatNodePath(nodePath: string | null): string {
    if (!nodePath) {
      return "/Node/Not/Found";
    }
    let pathWithSlashes = nodePath.replace(/\./g, "/");
    let pathElements = pathWithSlashes.split("/").slice(2);
    let resultPath = pathElements.join(".");

    return resultPath;
  }

  return (
    <>
      {selectedNode ? (
        <Typography variant="body1" textAlign={"center"} mb={2}>
          Current starting node: {formatNodePath(selectedNode.path)}
        </Typography>
      ) : (
        <Typography variant="body1" textAlign={"center"} mb={2}>
          Please select a node to give the agent a starting point (
          <img src="/icons/edit_square.svg" alt="Logo" />)
        </Typography>
      )}

      <Paper
        sx={{
          borderColor: theme.palette.divider,
          maxWidth: "40%",
          padding: 3,
        }}
      >
        <Box display={"flex"} flexDirection={"row"}>
          <Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              mb={2}
              alignItems={"center"}
            >
              <CustomToggleButton
                handleClick={() => {}}
                value={""}
                selected={false}
              >
                {getAgentIcon(selectedAgent)}
              </CustomToggleButton>
              <Typography variant="body1" textAlign={"start"} ml={2}>
                {getAgentTitle(selectedAgent)}
              </Typography>
            </Box>
            <Box ml={2}>{getAgentDescription(selectedAgent)}</Box>
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default AgentDescription;
