import { AssignedTo } from "@/interfaces/IChatRoom";
import { MessageUser } from "./types";
import { IUserAvatar } from "@/components/User/types";

export const mapAssignedToToUser = (assignedTo: AssignedTo): IUserAvatar => ({
  firstName: assignedTo.first_name,
  lastName: assignedTo.last_name,
  email: assignedTo.email,
  color: assignedTo.color,
  secondaryColor: assignedTo.secondary_color,
});

export const mapMessageUserToUser = (user: MessageUser): IUserAvatar => ({
  firstName: user.first_name,
  lastName: user.last_name,
  email: user.email,
  color: user.color,
  secondaryColor: user.secondary_color,
});
