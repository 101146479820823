import * as React from "react";
import { Box, Card, Chip, CircularProgress, Typography } from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { mapStatusesColors, mapCriticalitiesColors } from "./helpers";
import { Circle } from "@mui/icons-material";
import { Priorities } from "../types";
import Timestamp from "@/components/Typographies/Timestamp";
import AssignUserMenu from "./AssignUserMenu";
import { code_error_put } from "@/services/Blar/Integrations";
import { Context } from "@/ContextProvider";
import { ChatsRoomResponse } from "@/interfaces/IChatRoom";
import UserAvatars from "./UserAvatars";
import { mapMessageUserToUser } from "../helpers";
import { useTheme } from "@mui/material/styles";

interface CardProps {
  selected: boolean;
  data: ChatsRoomResponse;
  setRefreshChatRoomsTimestamp: (timestamp: number) => void;
  onClick?: () => void;
}

const MessageCard: React.FC<CardProps> = ({
  selected,
  data,
  setRefreshChatRoomsTimestamp,
  onClick,
}) => {
  const theme = useTheme();

  const [loading, setLoading] = React.useState(false);
  const { showMessage } = React.useContext(Context);
  const {
    has_unread_messages: hasUnreadMessages,
    trigger_type: type,
    trigger_id: triggerId,
    trigger: {
      source,
      priority: criticality,
      state: status,
      assigned_to: assignedTo,
    },
    users,
    name: bodyText,
    last_message_time: timestamp,
  } = data;
  const assignUser = async (userId: number) => {
    try {
      setLoading(true);
      await code_error_put({ id: triggerId, assigned_to: userId });
      setRefreshChatRoomsTimestamp(Date.now());
    } catch (error) {
      showMessage("error", "Failed to assign user");
    } finally {
      setLoading(false);
    }
  };
  const getCardIcon = (type: string, source?: string): React.ReactNode => {
    switch (type) {
      case "codeerror":
        switch (source) {
          case "sentry":
            return (
              <img
                src="/integrations/SentryIcon.svg"
                alt="Sentry Logo"
                style={{ height: 12 }}
              />
            );
          default:
            return null;
        }
      case "baseuser":
        return (
          <AccountCircleOutlinedIcon
            sx={{ fontSize: 15, marginRight: 0, marginLeft: 0 }}
          />
        );
      default:
        return "info";
    }
  };

  const getCriticallityBadgeIcon = (
    criticality: Priorities | null | undefined
  ): React.ReactNode => {
    if (criticality) {
      return (
        <Chip
          sx={{ marginRight: "5px" }}
          label={criticality}
          color={mapCriticalitiesColors(criticality)}
          variant="outlined"
          size="small"
        />
      );
    }
    return <></>;
  };

  return (
    <Card
      onClick={onClick}
      sx={{
        cursor: onClick ? "pointer" : "default",
        background: selected
          ? "#30363D"
          : hasUnreadMessages
          ? "#2B456B"
          : "#161B22",
        border: "1px solid",
        borderColor: hasUnreadMessages
          ? "rgba(11, 100, 255, 0.5)"
          : theme.palette.divider,
        paddingLeft: "16px",
        paddingRight: "16px",
      }}
    >
      <Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          {getCardIcon(type, source)}
          {status && (
            <Circle
              color={mapStatusesColors(status)}
              sx={{
                height: "12px",
                textAlign: "start",
                fontSize: "inherit",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            />
          )}
          {getCriticallityBadgeIcon(criticality)}
        </Box>
        <Box mt={1}>
          <Typography
            variant="body1"
            color="text.secondary"
            textAlign="start"
            fontSize={15}
            sx={{
              padding: "0px",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {bodyText}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={1}
        >
          {source === "sentry" ? (
            <>
              {loading ? (
                <CircularProgress size={5} />
              ) : (
                <AssignUserMenu onAssign={assignUser} assignedTo={assignedTo} />
              )}
            </>
          ) : (
            <UserAvatars users={users.map(mapMessageUserToUser) || []} />
          )}
          <Box flexGrow={1} />
          <Timestamp timestamp={timestamp} />
        </Box>
      </Box>
    </Card>
  );
};

export default MessageCard;
