import React from "react";
import { useState, useContext, useEffect } from "react";
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import BaseModal from "@/components/Modal/BaseModal";
import { Context } from "../../../../../ContextProvider";
import { create_repo } from "@/services/Blar/Repo_graph";
import { Repository } from "@/interfaces/IBackendOutputs";
import {
  check_integration_exists,
  github_list_repo_branches,
  github_list_repos,
} from "@/services/Blar/Integrations";
import { AxiosResponse } from "axios";
import { IntegrationType } from "@/interfaces/IIntegration";

type AddIntegrationModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  setCreatedObject: (createdObject: number) => void;
};

const AddRepoModal = ({
  open,
  setOpen,
  setCreatedObject,
}: AddIntegrationModalProps) => {
  const [repositories, setRepositories] = React.useState<Repository[]>([]);
  const [isGHConnected, setIsGHConnected] = React.useState(false);

  const [branches, setBranches] = React.useState<string[]>([]);
  const [selectedRepository, setSelectedRepository] = React.useState<
    number | null
  >(null);
  const [selectedBranch, setSelectedBranch] = React.useState<string | null>("");
  const [loading, setLoading] = useState(false);
  const { showMessage } = useContext(Context);
  const handleBranchChange = (event: SelectChangeEvent<string | null>) => {
    setSelectedBranch(event.target.value || "");
  };

  useEffect(() => {
    const getRepositories = async () => {
      try {
        setLoading(true);
        const repositoriesResponse: AxiosResponse = await github_list_repos();
        if (repositoriesResponse.status === 200) {
          setRepositories(repositoriesResponse.data);
        }
      } catch (error) {
        showMessage("error", "Failed to get repositories");
      } finally {
        setLoading(false);
      }
    };

    const checkGHIntegrationExists = async (): Promise<boolean> => {
      try {
        setLoading(true);

        const res = await check_integration_exists({
          source: IntegrationType.GITHUB,
        });
        setIsGHConnected(res.data);
        return res.data;
      } catch (error) {
        showMessage("error", "Failed to get Sentry integration");
      } finally {
        setLoading(false);
      }

      return false;
    };
    if (isGHConnected === false) {
      checkGHIntegrationExists();
    }

    if (isGHConnected) {
      getRepositories();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGHConnected]);

  const handleClose = () => {
    setSelectedRepository(null);
    setSelectedBranch(null);
    setOpen(false);
  };

  const handleRepositoryChange = (event: SelectChangeEvent<number | null>) => {
    if (event.target.value === null && typeof event.target.value === "string") {
      return;
    }
    setSelectedRepository(event.target.value as number);
  };

  useEffect(() => {
    // get repository branches
    if (selectedRepository !== null) {
      setLoading(true);
      try {
        github_list_repo_branches(
          repositories[selectedRepository].fullName
        ).then((res) => {
          setBranches(res.data);
        });
      } catch (error) {
        showMessage("error", "Failed to get branches");
      } finally {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repositories, selectedRepository]);

  const handleAddRepository = async () => {
    if (selectedRepository !== null && selectedBranch) {
      try {
        setLoading(true);
        const addRepoParams = {
          github_url: repositories[selectedRepository].url,
          main_branch: selectedBranch,
        };
        await create_repo(addRepoParams);
        setSelectedRepository(null);
        setCreatedObject(Date.now());
        handleClose();
      } catch (error) {
        showMessage("error", "Failed to add repository");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <BaseModal
      open={open}
      onClose={handleClose}
      title="Integrate New Repo"
      style={{ width: "50%" }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {isGHConnected ? (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <FormControl fullWidth>
                <InputLabel id="repository_select">
                  Select a repository
                </InputLabel>
                <Select
                  id="repository_select"
                  value={selectedRepository !== null ? selectedRepository : ""}
                  label="Select a repository"
                  onChange={handleRepositoryChange}
                >
                  {repositories.map((repo, index) => (
                    <MenuItem key={index} value={index}>
                      {" "}
                      {/* Adjust value if needed */}
                      {repo.fullName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {selectedRepository !== null && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <FormControl fullWidth>
                  <InputLabel id="branch_select">Select a branch</InputLabel>
                  <Select
                    id="branch_select"
                    value={selectedBranch || ""}
                    label="Select a branch"
                    onChange={handleBranchChange}
                  >
                    {branches.map((branch, index) => (
                      <MenuItem key={index} value={branch}>
                        {branch}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <LoadingButton
                loading={loading}
                size="large"
                variant="contained"
                onClick={handleAddRepository}
                color="blarSuccess"
                disabled={selectedRepository === null || !selectedBranch}
              >
                Save
              </LoadingButton>
            </Box>
          </>
        ) : (
          <Box
            sx={{ display: "flex", alignItems: "center", marginTop: "20px" }}
          >
            <Typography variant="body1">
              Please connect your Github account to add a repository
            </Typography>
          </Box>
        )}
      </Box>
    </BaseModal>
  );
};

export default AddRepoModal;
