import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LogoutIcon from "@mui/icons-material/Logout";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useNavigate } from "react-router-dom";
import { Context } from "@/ContextProvider";
import { Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';

interface MoreMenuProps {
  setSettingsAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
}

const MoreMenu: React.FC<MoreMenuProps> = ({ setSettingsAnchorEl }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { signOut, isGuest } = useContext(Context);

  React.useState<null | HTMLElement>(null);

  const handleClickSettings = () => {
    setSettingsAnchorEl(null);
    navigate("/settings");
  };

  const handleClickQueriesLogs = () => {
    setSettingsAnchorEl(null);
    navigate("/queries");
  };

  const handleLogout = () => {
    setSettingsAnchorEl(null);

    signOut();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignContent: "start",
      }}
    >
      { !isGuest && <Button
        size="small"
        variant="outlined"
        sx={{ 
          width: "fit-content", 
          border: "1px solid",
          borderColor: theme.palette.divider,
        }}
        onClick={handleClickSettings}
        color="blarWhite"
        startIcon={<SettingsOutlinedIcon />}
      >
        <Typography variant="body1">Settings</Typography>
      </Button>}
      { !isGuest && <Button
        size="small"
        variant="outlined"
        sx={{ 
          width: "fit-content", 
          border: "1px solid",
          borderColor: theme.palette.divider,
        }}
        onClick={handleClickQueriesLogs}
        color="blarWhite"
        startIcon={<DescriptionOutlinedIcon />}
      >
        <Typography variant="body1">Query logs</Typography>
      </Button>}
      <Button
        size="small"
        sx={{
          width: "fit-content", 
          border: "1px solid",
          borderColor: theme.palette.divider,
        }}
        variant="outlined"
        onClick={handleLogout}
        color="blarWhite"
        startIcon={<LogoutIcon />}
      >
        <Typography variant="body1">Logout</Typography>
      </Button>
    </Box>
  );
};

export default MoreMenu;
