import React, { useState } from "react";
import { Box, Typography, Grid, TextField, Alert } from "@mui/material";
import useStyles from "./styles/Integrations";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadingTypography from "./components/LoadingTypography";
import CustomSwitch from "@/components/Buttons/CustomSwitch";
import ContinueButton from "@/components/Buttons/ContinueButton";
import { post_graph_db_config } from "@/services/Blar/GraphDBConfig";
import { AxiosError } from "axios";
import { IGraphDBConfiguration } from "@/interfaces/IGraphConfig";

interface GraphDBConfigurationValues {
  data: IGraphDBConfiguration;
  authError?: string;
}

enum SwitchOptions {
  OPTION1 = "Yes",
  OPTION2 = "No. Do it for me",
}

interface GraphDBConfigurationProps {
  continueOnboarding: () => void;
}

const GraphDBConfigurationComponent: React.FC<GraphDBConfigurationProps> = ({
  continueOnboarding,
}) => {
  const classes = useStyles();
  const [selectedOption, setSelectedOption] = useState(SwitchOptions.OPTION1);

  const formik = useFormik<GraphDBConfigurationValues>({
    validateOnChange: true,
    initialValues: {
      data: {
        use_own_db: true,
        uri: "",
        user: "",
        password: "",
      },
    },
    isInitialValid: false,
    validationSchema: Yup.object({
      data: Yup.object({
        use_own_db: Yup.boolean().required("Required"),
        uri: Yup.string().when("use_own_db", {
          is: (use_own_db: boolean) => use_own_db === true,
          then: () => Yup.string().required("Required"),
          otherwise: () => Yup.string().notRequired(),
        }),
        user: Yup.string().when("use_own_db", {
          is: (use_own_db: boolean) => use_own_db === true,
          then: () => Yup.string().required("Required"),
          otherwise: () => Yup.string().notRequired(),
        }),
        password: Yup.string().when("use_own_db", {
          is: (use_own_db: boolean) => use_own_db === true,
          then: () => Yup.string().required("Required"),
          otherwise: () => Yup.string().notRequired(),
        }),
      }),
    }),
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      setSubmitting(true);
      try {
        if (!values.data.use_own_db) {
          await post_graph_db_config({ use_own_db: false });
          continueOnboarding();
          return;
        }
        await post_graph_db_config(values.data);
        continueOnboarding();
      } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.status === 400) {
          if (axiosError.response.data) {
            const errorData = axiosError.response.data as {
              detail: { [key: string]: string[] };
            };
            const errorDataDetails = errorData.detail;
            const errors: string[] = [];
            for (const key of Object.keys(errorDataDetails)) {
              if (errorDataDetails.hasOwnProperty(key)) {
                errors.push(`${key}: ${errorDataDetails[key]}`);
              }
            }
            const errorString: string = errors.reduce(
              (acc, curr) => acc + curr + "\n",
              ""
            );
            setErrors({ authError: errorString });
          }
        }
      }
      setSubmitting(false);
    },
  });

  const handleSwitchClick = async (option: SwitchOptions) => {
    const useOwnDb = option === SwitchOptions.OPTION1;
    formik.setFieldValue("data.use_own_db", useOwnDb, true).then(() => {
      setSelectedOption(option);
    });
  };

  return (
    <Box className={classes.root}>
      <LoadingTypography
        text="1. Configure Graph Database"
        loading={false}
        stepCompleted={false}
      />
      <Box className={classes.loggerToolBox}>
        <Typography fontSize={20} fontWeight={300} variant="body2" align="left">
          To store and manage the knowledge graph of your code repository
          efficiently, we need to implement a graph database solution
        </Typography>
        <br />
        <Typography fontSize={20} variant="body2" align="left">
          Would you like to use your own graph database?
        </Typography>
        <br />
        <CustomSwitch
          options={[SwitchOptions.OPTION1, SwitchOptions.OPTION2]}
          initialActiveOption={SwitchOptions.OPTION1}
          clickHandler={handleSwitchClick}
        />
        <br />
        {selectedOption === SwitchOptions.OPTION1 && (
          <>
            <Typography fontSize={20} variant="body2" align="left">
              Add conection details of your graph database
            </Typography>
            <br />
            <Grid
              container
              rowSpacing={2}
              direction="column"
              className={classes.form}
            >
              <Grid item sx={{ width: "100%" }}>
                <TextField
                  fullWidth
                  InputProps={{
                    sx: {
                      borderRadius: "10px",
                    },
                  }}
                  placeholder="URI"
                  type="text"
                  id="data.uri"
                  {...formik.getFieldProps("data.uri")}
                  error={
                    formik.touched.data?.uri && Boolean(formik.errors.data?.uri)
                  }
                  helperText={
                    formik.touched.data?.uri && formik.errors.data?.uri
                  }
                />
              </Grid>
              <Grid item sx={{ width: "100%" }}>
                <TextField
                  fullWidth
                  InputProps={{
                    sx: {
                      borderRadius: "10px",
                    },
                  }}
                  placeholder="USER"
                  type="text"
                  id="data.user"
                  {...formik.getFieldProps("data.user")}
                  error={
                    formik.touched.data?.user &&
                    Boolean(formik.errors.data?.user)
                  }
                  helperText={
                    formik.touched.data?.user && formik.errors.data?.user
                  }
                />
              </Grid>
              <Grid item sx={{ width: "100%" }}>
                <TextField
                  fullWidth
                  InputProps={{
                    sx: {
                      borderRadius: "10px",
                    },
                  }}
                  placeholder="PASSWORD"
                  type="password"
                  id="data.password"
                  {...formik.getFieldProps("data.password")}
                  error={
                    formik.touched.data?.password &&
                    Boolean(formik.errors.data?.password)
                  }
                  helperText={
                    formik.touched.data?.password &&
                    formik.errors.data?.password
                  }
                />
              </Grid>
              <Grid item>
                {formik.errors.authError && (
                  <Alert severity="error">{formik.errors.authError}</Alert>
                )}
              </Grid>
            </Grid>
            <br />
          </>
        )}
      </Box>
      <ContinueButton
        continueOnboarding={formik.handleSubmit}
        loading={formik.isSubmitting}
        disabled={formik.isSubmitting || !formik.isValid}
      />
    </Box>
  );
};

export default GraphDBConfigurationComponent;
