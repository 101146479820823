import React, { useRef } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

type SearchBarProps = {
  placeholder?: string;
  onChange: (value: string) => void;
  sx?: React.CSSProperties;
};

const SearchBar: React.FC<SearchBarProps> = ({
  placeholder = "Search",
  onChange,
  sx = {},
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };
  const inpRef = useRef<HTMLInputElement | null>(null);

  return (
    <TextField
      inputRef={inpRef}
      onClick={() => inpRef.current?.focus()}
      variant="outlined"
      placeholder={placeholder}
      fullWidth
      onChange={handleInputChange}
      sx={{ ...sx }}
      InputProps={{
        sx: {
          borderRadius: "10px",
          padding: "5px",
          fontWeight: "500",
          "& .MuiOutlinedInput-input": {
            padding: "5px",
          },
        },
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon sx={{ color: "#454545B2", marginRight: "5px" }} />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchBar;
