import {
  AgentStep,
  ChatRoomResponse,
  InputTag,
} from "@/pages/Chat/components/types";
import { blarClient } from "./Client";

export interface CreateChatRoomParams {
  node_id: string;
  agent: string;
}

export interface SendMessageParams {
  message: string;
  node_id?: string;
  type: "blar_agent_message";
}

export interface SendTagParams {
  message: string;
  tags: InputTag[];
  type: "tag_message";
}

interface MessageTag {
  id: number;
  value: string;
  email: string;
  first_name: string;
  last_name: string;
  prefix: string;
  __isValid: boolean;
  __tagId: string;
}
export interface MessageTagParams {
  chat_room_id: number;
  Tag: MessageTag[];
}

export async function send_message(params: CreateChatRoomParams) {
  return await blarClient.request("post", `/agents/chat/`, params);
}

export async function post_message_tag(data: MessageTagParams) {
  return await blarClient.request("post", `/agents/message-tag`, data);
}

export async function get_chat_room_messages(
  chatRoomId: number
): Promise<ChatRoomResponse> {
  return (await blarClient.request("get", `/agents/chat/${chatRoomId}`))
    .data as ChatRoomResponse;
}

export async function mark_as_read(chatRoomId: number) {
  return await blarClient.request("patch", `/agents/mark-chat-as-read`, {
    chat_room_id: chatRoomId,
  });
}

export function process_message_chunk(value: any): AgentStep {
  return JSON.parse(value);
}
