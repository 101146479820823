import React, { useContext, useEffect } from "react";
import {
  Button,
  Box,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";
import useStyles from "./styles/Integrations";
import ContinueButton from "@/components/Buttons/ContinueButton";
import {
  check_integration_exists,
  github_list_repo_branches,
  github_list_repos,
  github_repository_info,
} from "@/services/Blar/Integrations";
import { IntegrationType } from "@/interfaces/IIntegration";
import handleGithubIntegration, {
  handleRedirectToGitHubApp,
} from "@/pages/Settings/components/Integrations/handleIntegrations/handleGithub";
import { Context } from "@/ContextProvider";
import { useSearchParams } from "react-router-dom";
import { AxiosResponse } from "axios";
import { Repository } from "@/interfaces/IBackendOutputs";
import { create_repo, list_repos } from "@/services/Blar/Repo_graph";
import { LoadingButton } from "@mui/lab";
import LoadingTypography from "./components/LoadingTypography";

interface CoreIntegrationProps {
  continueOnboarding: () => void;
}

const CoreIntegrationComponent: React.FC<CoreIntegrationProps> = ({
  continueOnboarding,
}) => {
  const classes = useStyles();
  const { showMessage } = useContext(Context);
  const [searchParams] = useSearchParams();
  const selectedIntegration = searchParams.get("integration");
  const [isGHConnected, setIsGHConnected] = React.useState<
    boolean | null | "pending"
  >(null);
  const [isRepoConnected, setIsRepoConnected] = React.useState(false);
  const [repositories, setRepositories] = React.useState<Repository[]>([]);
  const [branches, setBranches] = React.useState<string[]>([]);
  const [selectedRepository, setSelectedRepository] = React.useState<
    number | null
  >(null);
  const [selectedBranch, setSelectedBranch] = React.useState<number | null>(
    null
  );
  const [loadingGithub, setLoadingGithub] = React.useState(false);
  const [loadingRepos, setLoadingRepos] = React.useState(false);
  const [reloadCheck, setReloadCheck] = React.useState(false);
  const [isRepositoryToBig, setIsRepositoryToBig] = React.useState(false);
  const [pendingGithub, setPendingGithub] = React.useState(false);

  const checkGHIntegrationExists = async (): Promise<boolean | "pending"> => {
    try {
      setLoadingGithub(true);

      const res = await check_integration_exists({
        source: IntegrationType.GITHUB,
      });
      setIsGHConnected(res.data);
      return res.data;
    } catch (error) {
      showMessage("error", "Failed to get Sentry integration");
    } finally {
      setLoadingGithub(false);
    }

    return false;
  };

  useEffect(() => {
    if (selectedIntegration && isGHConnected !== null && !isGHConnected) {
      setLoadingGithub(true);
      handleGithubIntegration(showMessage, searchParams)
        .then(() => {
          setReloadCheck(true);
        })
        .finally(() => {
          setLoadingGithub(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIntegration, isGHConnected]);

  useEffect(() => {
    const getRepositories = async () => {
      try {
        setLoadingRepos(true);
        const repositoriesResponse: AxiosResponse = await github_list_repos();
        if (repositoriesResponse.status === 200) {
          setRepositories(repositoriesResponse.data);
        }
      } catch (error) {
        showMessage("error", "Failed to get repositories");
      } finally {
        setLoadingRepos(false);
      }
    };

    checkGHIntegrationExists()
      .then(async (isConnected) => {
        if (isConnected === true) {
          const repoExists = await checkIfRepoExists();
          if (!repoExists) {
            getRepositories();
          }
        } else if (isConnected === "pending") {
          setPendingGithub(true);
        }
      })
      .catch(() => {
        showMessage("error", "Failed to get GitHub integration");
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadCheck]);

  useEffect(() => {
    // get repository branches
    if (selectedRepository !== null) {
      github_list_repo_branches(repositories[selectedRepository].fullName)
        .then((res) => {
          setBranches(res.data);
        })
        .catch(() => {
          showMessage("error", "Failed to get branches");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repositories, selectedRepository]);

  const checkIfRepoExists = async () => {
    try {
      setLoadingRepos(true);

      const repoRequest = await list_repos();
      const repositories = repoRequest.data.results;

      if (repositories.length > 0) {
        const repositoriesForSelected = repositories.map((repo: any) => ({
          fullName: repo.name,
          url: repo.url,
        }));
        setRepositories(repositoriesForSelected);
        setIsRepoConnected(true);
        return true;
      }
    } catch (error) {
      showMessage("error", "Failed to get repositories");
    } finally {
      setLoadingRepos(false);
    }
    return false;
  };

  const handleRepositoryChange = async (
    event: SelectChangeEvent<number | null>
  ) => {
    if (event.target.value === null || typeof event.target.value === "string") {
      return;
    }
    setSelectedRepository(event.target.value!);
    const repositoryName = repositories[event.target.value!].fullName;
    const org = repositoryName.split("/")[0];
    const repo = repositoryName.split("/")[1];
    try {
      const repositoryInfoRequest = await github_repository_info(org, repo);
      const repositoryInfo = repositoryInfoRequest.data;
      const size = repositoryInfo.size;
      // check if the repository is too big, 2GB
      if (size > 2000000) {
        setIsRepositoryToBig(true);
      } else {
        setIsRepositoryToBig(false);
      }
    } catch (error) {}
  };

  const handleBranchChange = (event: SelectChangeEvent<number | null>) => {
    if (event.target.value === null || typeof event.target.value === "string") {
      return;
    }
    setSelectedBranch(event.target.value!);
  };

  const handleAddRepository = async () => {
    if (selectedRepository !== null && selectedBranch !== null) {
      try {
        setLoadingRepos(true);
        const addRepoParams = {
          github_url: repositories[selectedRepository].url,
          main_branch: branches[selectedBranch],
        };
        await create_repo(addRepoParams);
        setSelectedRepository(null);
        await checkIfRepoExists();
      } catch (error) {
        showMessage("error", "Failed to add repository");
      } finally {
        setLoadingRepos(false);
      }
    }
  };

  return (
    <Box className={classes.root}>
      <LoadingTypography
        text="1. Connect to GitHub"
        loading={loadingGithub}
        stepCompleted={isGHConnected === true || false}
      />
      {isGHConnected === "pending" && (
        <Typography variant="body1" color="info" mb={2}>
          Waiting for GitHub approval, please ask your organization admin to
          approve the request
        </Typography>
      )}
      {!isGHConnected && (
        <Box className={classes.connectButton}>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<GitHubIcon />}
            style={{ fontSize: "20px" }}
            onClick={() => handleRedirectToGitHubApp(showMessage)}
            disabled={isGHConnected || false}
          >
            Connect
          </Button>
        </Box>
      )}
      <LoadingTypography
        text="2. Select the repositories"
        loading={loadingRepos}
        stepCompleted={isRepoConnected}
      ></LoadingTypography>
      <Box className={classes.loggerToolBox}>
        {isGHConnected === true && !isRepoConnected && !loadingRepos && (
          <Box className={classes.repositorySelectContainer}>
            <FormControl fullWidth>
              <InputLabel id="repository_select">
                Select a repository
              </InputLabel>
              <Select
                id="repository_select"
                color="secondary"
                value={selectedRepository}
                label="Repository"
                placeholder="Select a repository"
                onChange={handleRepositoryChange}
              >
                {repositories.map((repo: Repository, index: number) => (
                  <MenuItem key={index} value={index}>
                    {repo.fullName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {isRepositoryToBig && (
              <Typography variant="body1" color="error">
                Currently we don't support repositories bigger than 2GB. PLease
                select another one
              </Typography>
            )}
            <br />
            {selectedRepository !== null && (
              <FormControl
                fullWidth
                disabled={!isGHConnected || isRepoConnected}
              >
                <InputLabel id="branch_select">Select a branch</InputLabel>
                <Select
                  id="branch_select"
                  value={selectedBranch}
                  label="Branches"
                  placeholder="Select a branch"
                  onChange={handleBranchChange}
                >
                  {branches.map((branch: string, index: number) => (
                    <MenuItem key={index} value={index}>
                      {branch}
                    </MenuItem>
                  ))}
                </Select>
                <br />
              </FormControl>
            )}
            <LoadingButton
              variant="contained"
              color="secondary"
              startIcon={<GitHubIcon />}
              style={{ fontSize: "20px", margin: 0 }}
              onClick={handleAddRepository}
              loading={loadingRepos}
              disabled={
                !isGHConnected ||
                selectedRepository === null ||
                selectedBranch === null ||
                isRepositoryToBig
              }
            >
              Add Repository
            </LoadingButton>
          </Box>
        )}
      </Box>
      <ContinueButton
        continueOnboarding={continueOnboarding}
        disabled={!(isGHConnected && isRepoConnected)}
      />
    </Box>
  );
};

export default CoreIntegrationComponent;
