import React, { useCallback, useContext, useEffect, useRef } from "react";
import Tagify from "@yaireo/tagify";
import "@yaireo/tagify/dist/tagify.css"; // Import Tagify default styles
import "./styles/ChatInput.css"; // Import your custom styles
import { CircularProgress } from "@mui/material";
import { list_users } from "@/services/Blar/Company";
import { Context } from "@/ContextProvider";
import debounce from "lodash.debounce"; // Import debounce from lodash

const TagifyComponent = ({
  sendMessage,
  loading,
  setLoading,
  selectedChatRoom,
  selectedAgent,
  selectedNode,
  handleSendTag,
}) => {
  const inputRef = useRef(null);
  const tagifyRef = useRef(null);
  const { showMessage, companyName } = useContext(Context);

  const mapUsersToTagify = (users) => {
    if (users.length === 0) return [];
    return users.map((user) => ({
      id: user.id,
      value: user.id,
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
    }));
  };

  useEffect(() => {
    if (tagifyRef.current) {
      tagifyRef.current.setDisabled(loading);
    }
  }, [loading]);

  const suggestionItemTemplate = (item) => {
    const isSelected = tagifyRef.current?.isTagDuplicate(item.id);
    const attributes = tagifyRef.current?.getAttributes(item);
    const itemClasses = [
      tagifyRef.current?.settings.classNames.dropdownItem,
      isSelected
        ? tagifyRef.current?.settings.classNames.dropdownItemSelected
        : "",
      item.class || "",
    ]
      .join(" ")
      .trim();

    return `
      <div 
        ${attributes || ""}
        class="${itemClasses}"
        tabindex="0"
        role="option">
        <strong>${item.first_name ? item.first_name : "John"} ${
      item.last_name ? item.last_name : "Doe"
    }</strong><br>
        <span>${item.email}</span>
      </div>
    `;
  };

  const onMessageSend = async () => {
    const tagify = tagifyRef.current;
    const tags = tagify.value;
    const input = tagify.DOM.input.textContent;

    const message = input.trim();

    if (tags.length > 0) {
      const uniqueTags = Array.from(
        new Map(tags.map((tag) => [tag.value, tag])).values()
      );
      try {
        setLoading(true);
        await handleSendTag(tagify.state.lastOriginalValueReported, uniqueTags);
        tagify.removeAllTags();
      } catch (error) {
        showMessage("error", "Failed to send message users");
      } finally {
        setLoading(false);
      }

      return;
    }
    if (message) {
      try {
        sendMessage(message);
        tagify.removeAllTags();
      } catch (error) {}
    }
  };

  useEffect(() => {
    if (inputRef.current && !tagifyRef.current) {
      const tagify = new Tagify(inputRef.current, {
        mode: "mix",
        pattern: /(?:^|\s)@/,
        tagTextProp: "email",
        enforceWhitelist: true,
        placeholder: "Message Blar or @mention a user",
        mixTagsInterpolator: ["<blar-tag>", "</blar-tag>"],
        templates: {
          dropdownItem: suggestionItemTemplate,
        },

        trim: false,
        editTags: false,
        addTagOnBlur: false,
        onChangeAfterBlur: false,
        addTagOn: ["enter", "tab"],
        searchKeys: ["email", "first_name", "last_name"],
        dropdown: {
          enabled: 1,
          mapValueTo: "email",
          highlightFirst: true,
          className: "chat-input",
          placeAbove: true,
        },
      });
      tagify.setDisabled(loading);

      tagifyRef.current = tagify;

      tagify.on("keydown", (e) => {
        if (
          e.detail.event.key === "Enter" &&
          !e.detail.event.shiftKey &&
          !tagify.state.tag &&
          (tagify.suggestedListItems.length === 0 || // No suggestions are shown
            !tagify.state.dropdown.visible)
        ) {
          onMessageSend(tagify);
        }
      });
      tagify.DOM.input.addEventListener("keydown", function (e) {
        if (
          e.key === "Enter" &&
          !e.shiftKey &&
          !tagify.state.tag &&
          (tagify.suggestedListItems.length === 0 || // No suggestions are shown
            !tagify.state.dropdown.visible)
        )
          e.preventDefault();
      });

      const debouncedFetchUsers = debounce(async (searchValue) => {
        try {
          tagify.loading(true);
          const usersResult = (
            await list_users({ limit: 10, offset: 0 }, { search: searchValue })
          ).data.results;
          tagify.whitelist = mapUsersToTagify(usersResult);
        } catch (error) {
          showMessage("error", "Failed to fetch users");
        } finally {
          tagify.loading(false);
          tagify.dropdown.toggle(true);
        }
      }, 300);

      tagify.on("input", async (e) => {
        const prefix = e.detail.prefix;
        tagify.whitelist = [];
        if (prefix) {
          if (prefix.trim() === "@" && e.detail.value.length > 1) {
            if (e.detail.value.includes(" ")) {
              tagify.dropdown.hide();
              return;
            }
            if (companyName !== "Playground Company") {
              await debouncedFetchUsers(e.detail.value);
            }
            if (e.detail.value.length > 1) tagify.dropdown.show(e.detail.value);
          }
        }
      });
    }
    // eslint-disable-next-line
  }, [inputRef, selectedChatRoom, selectedAgent, selectedNode]);

  return (
    <>
      <textarea
        className="chat-input"
        style={{ width: "100%", maxHeight: "20vh", overflowY: "auto" }}
        ref={inputRef}
        name="mix"
        onChange={() => {}}
        value={""}
      />
      {loading ? (
        <CircularProgress />
      ) : (
        <img
          src="/icons/send.svg"
          alt="Send"
          id="send-button"
          onClick={() => onMessageSend()}
          style={{
            cursor: "pointer",
            height: "20px",
            marginLeft: "10px",
          }}
        />
      )}
    </>
  );
};

export default React.memo(TagifyComponent);
