import { Step } from "react-joyride";

export const tutorialSteps: Step[] = [
  {
    target: "#new-chat",
    content: "Start a new chat by clicking here.",
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    hideFooter: true,
    spotlightClicks: true,
  },
  {
    target: "#agent-selector",
    content: "Select an Agent to help you with your request.",
    placement: "right",
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    hideFooter: true,

    spotlightClicks: true,
  },
  {
    target: "#file-explorer",
    content:
      "Select a starting node in the file explorer. (Folders don't count as a starting node)",
    placement: "right",
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    spotlightClicks: true,
  },
  {
    target: "#start-chat-button",
    content:
      "After a starting node is selected Click here to start a new chat. ",
    placement: "right",
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    spotlightClicks: true,
  },
  {
    target: "#message-input",
    content: "Write your request and hit enter",
    placement: "top",
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    spotlightClicks: true,
  }
];
