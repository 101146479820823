import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, IconButton, Alert } from "@mui/material";
import BaseModal from "@/components/Modal/BaseModal";
import AddIcon from "@mui/icons-material/Add";

import {
  create_invitation,
  delete_invitation,
  list_invitations,
} from "@/services/Blar/Company";
import TableWithActions from "@/components/Tables/TableWithActions";
import { TableRowData } from "@/components/Tables/types";
import { InvitationLink } from "../types";
import { convertInvitationLinkToRow } from "../helper";

type AddIntegrationModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  showMessage: (
    type: "success" | "error" | "info" | "warning",
    message: string
  ) => void;
};

const defaultLinkTitles = ["Created by", "Link", "Expiry Date", "Expired"];

const AddUsersModal = ({
  open,
  setOpen,
  showMessage,
}: AddIntegrationModalProps) => {
  const [loading, setLoading] = useState(false);
  const [invitationLinkRows, setInvitationLinkRows] = useState<TableRowData[]>(
    []
  );
  const [update, setUpdate] = useState(Date.now());
  const baseURL = window.location.origin;

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreateLink = async () => {
    try {
      setLoading(true);
      const response = await create_invitation();
      if (response.status === 201) {
        showMessage("success", "Link created successfully");
        setUpdate(Date.now());
      }
    } catch (error) {
      showMessage("error", "Error creating link");
    } finally {
      setLoading(false);
    }
  };

  const handleLinkDelete = async (index: number) => {
    try {
      await delete_invitation(
        invitationLinkRows[index]._token.content as string
      );
      setUpdate(Date.now());
      showMessage("success", "Link deleted successfully");
    } catch (error) {
      showMessage("error", "Error deleting link");
    }
  };

  useEffect(() => {
    async function fetchReposData() {
      try {
        setLoading(true);
        const reposResult = (await list_invitations()).data as InvitationLink[];
        const repoRows = convertInvitationLinkToRow(
          reposResult,
          showMessage,
          baseURL
        );
        setInvitationLinkRows(repoRows);
      } catch (error) {
        showMessage("error", `Failed to fetch users`);
      } finally {
        setLoading(false);
      }
    }

    fetchReposData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  return (
    <BaseModal
      open={open}
      onClose={handleClose}
      title="Add new Users"
      style={{ width: "80%" }}
    >
      <Box>
        <Grid container alignItems="center" sx={{ marginBottom: "20px" }}>
          <Grid item>
            <Typography variant="h6">Create Link</Typography>
          </Grid>
          <Grid item>
            <IconButton
              sx={{
                border: "1px solid #FFFFFF4D",
                color: "gray",
                marginLeft: "10px",
              }}
              size="small"
              onClick={handleCreateLink}
            >
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Alert severity="warning" sx={{ marginBottom: "20px" }}>
          Share these links only with people you trust. They will expire in 24
          hours.
        </Alert>
        <TableWithActions
          tableRows={invitationLinkRows}
          defaultTitles={defaultLinkTitles}
          loading={loading}
          onDelete={handleLinkDelete}
        />
      </Box>
    </BaseModal>
  );
};

export default AddUsersModal;
