import React from "react";
import { Typography, Tooltip } from "@mui/material";

// Define the structure of the data inside <blar-tag>
interface BlarTagData {
  id: number;
  value: string;
  email: string;
  first_name: string | null;
  last_name: string | null;
  prefix: string;
}

// Custom component to parse and render the blar-tag
const RenderUserMessage: React.FC<{ message: string }> = ({ message }) => {
  // Regex to capture content within <blar-tag>...</blar-tag>
  const blarTagRegex = /<blar-tag>(.*?)<\/blar-tag>/g;
  // Function to parse the message and replace <blar-tag> with React components
  const parseMessage = (message: string) => {
    const parts = message.split(blarTagRegex);
    return parts.map((part, index) => {
      if (index % 2 === 0) {
        // Regular text
        return <span key={index}>{part}</span>;
      } else {
        // JSON content within <blar-tag>
        let tagData: BlarTagData;
        try {
          tagData = JSON.parse(part) as BlarTagData;
        } catch (e) {
          console.error("Invalid JSON format in <blar-tag>", e);
          return <span key={index}>{`<blar-tag>${part}</blar-tag>`}</span>; // Return original tag if parsing fails
        }

        // Destructure values and handle null cases
        const { first_name = "John", last_name = "Doe", email = "" } = tagData;
        const displayName = `${first_name || "John"} ${last_name || "Doe"}`;

        // Return the formatted string as a Tooltip with email on hover
        return (
          <Tooltip key={index} title={email} placement="top">
            <span style={{ color: "#4593F8", cursor: "pointer" }}>
              @{displayName}
            </span>
          </Tooltip>
        );
      }
    });
  };

  return <Typography variant="body1">{parseMessage(message)}</Typography>;
};

export default RenderUserMessage;
