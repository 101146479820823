import React, { useContext, useEffect, useState } from "react";
import { Button, Typography, Box, Link } from "@mui/material";
import useStyles from "./styles/Integrations";
import ContinueButton from "@/components/Buttons/ContinueButton";
import {
  check_integration_exists,
  slack_oauth,
} from "@/services/Blar/Integrations";
import { IntegrationType } from "@/interfaces/IIntegration";
import { Context } from "@/ContextProvider";
import CheckIcon from "@mui/icons-material/Check";
import SentryModal from "@/components/Integrations/SentryModal";
import { integrations } from "@/components/Integrations/integrations";
import CopyableTextField from "@/components/TextField/CopyableTextField";

interface NotificationsIntegrationProps {
  continueOnboarding: () => void;
}

const NotificationsIntegrationComponent: React.FC<
  NotificationsIntegrationProps
> = ({ continueOnboarding }) => {
  const classes = useStyles();
  const { showMessage, companyId } = useContext(Context);
  const [isSlackConnected, setIsSlackConnected] = useState(false);
  const [isSentryConnected, setIsSentryConnected] = useState(false);
  const [openSentryModal, setOpenSentryModal] = useState(false);
  const [sentryRefeshState, setSentryRefeshState] = useState(Date.now());

  const webhookUrl =
    process.env.REACT_APP_API_URL +
    `/integrations/sentry/internal/webhook/${companyId}/`;

  const checkSentryConnection = () => {
    try {
      check_integration_exists({ source: IntegrationType.SENTRY }).then(
        (res) => {
          const is_connected = res.data;
          setIsSentryConnected(is_connected);
        }
      );
    } catch (error) {
      showMessage("error", "Failed to get Slack integration");
    }
  };

  useEffect(() => {
    checkSentryConnection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sentryRefeshState]);

  useEffect(() => {
    // Function to check Sentry connection
    // Function to check Slack connection
    const checkSlackConnection = () => {
      check_integration_exists({ source: IntegrationType.SLACK })
        .then((res) => {
          const is_connected = res.data;
          setIsSlackConnected(is_connected);
        })
        .catch(() => {
          showMessage("error", "Failed to get Slack integration");
        });
    };

    checkSlackConnection();
    checkSentryConnection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSlackIntegration = () => {
    slack_oauth()
      .then((response) => {
        window.location.href = response.data.url;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Box className={classes.root}>
      <Typography
        variant="h3"
        align="center"
        fontSize={40}
        sx={{
          lineHeight: "40px",
        }}
      >
        3. Connect to Sentry (optional)
        {isSentryConnected && <CheckIcon fontSize={"large"} color="success" />}
      </Typography>
      {!isSentryConnected && (
        <>
          <br/>
          <Box className={classes.loggerToolBox}>
            <Typography fontSize={20} variant="body2" align="left">
              To connect to Sentry, you will need to follow the steps listed on
              the{" "}
              <Link
                href="https://blar.io/docs"
                target="_blank"
                rel="noopener noreferrer"
              >
                documentation
              </Link>
            </Typography>
            <br />
            <Typography fontSize={20} variant="body2" align="left">
              If you encounter any problems with Sentry, or have another
              monitoring tool, please schedule a call with our customer success
              team{" "}
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href="https://calendly.com/patdaher"
              >
                here
              </Link>
              !
            </Typography>
            <br />
          </Box>
        </>
      )}
      <Typography
        variant="h3"
        align="center"
        fontSize={40}
        sx={{
          lineHeight: "40px",
          marginBottom: "20px",
        }}
      >
        4. Connect to Slack (optional)
        {isSlackConnected && <CheckIcon fontSize={"large"} color="success" />}
      </Typography>
      {!isSlackConnected && (
        <Box className={classes.connectButton}>
          <Button
            variant="contained"
            color="secondary"
            startIcon={
              <img
                src="/integrations/SlackIcon.svg"
                alt="Slack"
                style={{ height: "20px" }}
              />
            }
            style={{ fontSize: "20px" }}
            onClick={handleSlackIntegration}
          >
            Connect
          </Button>
        </Box>
      )}
      <ContinueButton continueOnboarding={continueOnboarding} />
      <SentryModal
        open={openSentryModal}
        setOpen={setOpenSentryModal}
        integration={integrations[3]} // The las one is sentry
        setCreatedObject={setSentryRefeshState}
      />
    </Box>
  );
};

export default NotificationsIntegrationComponent;
