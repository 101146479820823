import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
  },
  inputText: {
    "&.MuiInputBase-root": {
      display: "flex",
      flexDirection: "column",
    },
  },
  form: {
    borderRadius: "10px",
  },
});

export default useStyles;
