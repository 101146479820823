import { useEffect, useState } from "react";
import "./styles.css";
import { Typography } from "@mui/material";

interface CustomSwitchProps<T> {
  options: T[];
  initialActiveOption: T | null;
  clickHandler: (option: T) => void;
}

export default function CustomSwitch<T extends string>({
  options,
  initialActiveOption,
  clickHandler,
}: CustomSwitchProps<T>) {
  const [activeOption, setActiveOption] = useState<T | null>(
    initialActiveOption
  );

  useEffect(() => {
    setActiveOption(initialActiveOption);
  }, [initialActiveOption]);

  const handleSwitchClick = (option: T) => {
    setActiveOption(option);
    clickHandler(option);
  };

  return (
    <div className="background">
      <div className="SwitchContainer">
        {options.map((option, index) => (
          <div
            key={option}
            className="ToggleItem"
            style={{
              outline:
                activeOption === option ||
                (activeOption === null && index === 0)
                  ? "1px #0000004d solid"
                  : "transparent",
            }}
            onClick={() => handleSwitchClick(option)}
          >
            <Typography
              variant="body1"
              sx={{
                color: activeOption === option ? "#575757" : "#45454580",
              }}
            >
              {option}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
}
