import { Paper, Typography } from "@mui/material";
import { memo, useEffect, useState } from "react";
import { Handle, Position, useStore, useViewport } from "reactflow";
import { GraphFilter } from "../../interfaces/IFilters";

interface Props {
  id: string;
  data: {
    level: number;
    name: string;
    leaf: boolean;
    max_level: number;
    size: number;
    labels: string[];
    selectedFilter: GraphFilter;
    highlightNode: string | undefined;
    highlightedPath: string[];
  };
}

export default memo(({ id, data }: Props) => {
  const [fontSize, setFontSize] = useState<number>(1);
  const [color, setColor] = useState<string>("#b1b1b7");
  const { zoom } = useViewport();

  const zoomSelector = (s: any) => {
    const zoom = s.transform[2];
    const minZoom = 0.08 * (data.level - 1);
    const showContent = minZoom <= zoom;
    return {
      content: showContent,
    };
  };

  const { content: showContent } = useStore(zoomSelector);

  useEffect(() => {
    const newfontSize = ((data.max_level - data.level) * 3 + 10) * (1 / zoom);

    setFontSize(newfontSize);
  }, [zoom, data.leaf, data.level, data.max_level]);

  useEffect(() => {
    let color = "#C6D1E740";
    if (id === data.highlightNode) {
      color = "#0055ED90";
    } else if (data.highlightedPath.includes(id)) {
      color = "#0055ED40";
    }
    setColor(color);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.selectedFilter, data.highlightNode]);

  return (
    <>
      {showContent ? (
        <>
          <Handle type="target" position={Position.Top} />

          <Paper
            id={id + "-blob"}
            sx={{
              borderRadius: "50%",
              width: data.size,
              height: data.size,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: color,
              opacity: 1,
            }}
          >
            <Typography
              variant="h6"
              style={{
                fontSize: fontSize,
                opacity: 1,
                marginTop: data.level % 2 === 0 ? "1em" : "-1em",
              }}
            >
              {data.name}
            </Typography>
          </Paper>
          <Handle type="source" position={Position.Bottom} />
        </>
      ) : (
        // Something has to be rendered for ReactFlow to find the node
        <>
          <Handle type="target" position={Position.Top} />
          <div
            id={id + "-blob"}
            style={{
              height: "1px",
              width: "1px",
              borderRadius: "50%",
              backgroundColor: "transparent",
            }}
          ></div>
          <Handle type="source" position={Position.Bottom} />
        </>
      )}
    </>
  );
});
