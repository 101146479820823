import React, { useContext } from "react";
import { Box, Grid } from "@mui/material";
import BaseModal from "@/components/Modal/BaseModal";
import StripePricingTable from "@/components/Payment/StripePricingTable";
import { Context } from "@/ContextProvider";

interface PaymentModalProps {
  open: boolean;
  onClose: () => void;
}

const PaymentModal: React.FC<PaymentModalProps> = ({ open, onClose }) => {
  const { companyId } = useContext(Context);

  return (
    <BaseModal
      open={open}
      onClose={onClose}
      title="Upgrade Your Plan"
      style={{ width: "30%" }}
    >
      <Box>
        <Grid
          container
          alignItems="center"
          sx={{ marginBottom: "20px" }}
        ></Grid>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <StripePricingTable companyId={companyId!} />
        </Box>
      </Box>
    </BaseModal>
  );
};

export default PaymentModal;
