import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    marginLeft: "30px",
  },
  connectButton: {
    margin: 0
  }
});

export default useStyles;
